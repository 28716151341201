import React from "react";

interface Props {
}

const Impressum: React.FC<Props> = () => {
    return (
        <>
            <div className="top  pb-5">
                <div className="lg:w-1/2 w-3/4 mx-auto text-center">
                    <h1 className="text-4xl font-bold pt-[150px] pb-5"
                        data-aos="fade-up">Datenschutz</h1>
                </div>
                <div className="lg:w-1/2 w-3/4 mx-auto">
                    <div className="pt-10">
                        <div className="flex lg:flex-row flex-col pb-10">
                            <div className="flex flex-col w-1/2">
                                <h3 className="text-2xl font-bold pb-5">Adresse</h3>
                                <div className={"pl-1"}>
                                    <p>Niklas Holy</p>
                                    <p>Schachenstrasse 17</p>
                                    <p>9430 St.Margrethen</p>
                                    <p>Schweiz</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"lg:w-1/2 w-3/4 mx-auto"}>
                    <h2 className="text-xl font-bold my-5 uppercase">Allgemein</h2>
                    <p>
                        Gestützt auf Artikel 13 der Schweizerischen Bundesverfassung und die datenschutzrechtlichen
                        Bestimmungen des Bundes (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer
                        Privatsphäre
                        sowie auf Schutz vor Missbrauch ihrer persönlichen Daten. Die Betreiber dieser Seiten nehmen den
                        Schutz
                        Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
                        entsprechend
                        der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.<br/>
                        <br/>
                        In Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie
                        möglich vor unberechtigtem Zugriff, Verlust, Missbrauch oder Verfälschung zu schützen.<br/>
                        <br/>
                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
                        E-Mail)
                        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte
                        ist
                        nicht möglich.<br/>
                        <br/>
                        Durch die Nutzung dieser Website erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung
                        von Daten gemäß der nachfolgenden Beschreibung einverstanden. Diese Website kann grundsätzlich
                        ohne Registrierung besucht werden. Daten wie aufgerufene Seiten oder Namen von aufgerufenen
                        Dateien,
                        Datum und Uhrzeit werden zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese
                        Daten
                        unmittelbar auf Ihre Person bezogen werden. Soweit auf unseren Seiten personenbezogene Daten
                        (beispielsweise Name,
                        Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
                        freiwilliger
                        Basis.
                        Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.<br/>
                    </p>
                    <h2 className="text-xl font-bold my-5 uppercase">Verarbeitung personenbezogener Daten</h2>
                    <p>
                        Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
                        identifizierbare Person beziehen.
                        Eine betroffene Person ist eine Person, über die personenbezogene Daten verarbeitet werden. Die
                        Verarbeitung
                        umfasst jeden Umgang mit personenbezogenen Daten, unabhängig von den verwendeten Mitteln und
                        Verfahren, insbesondere
                        die Speicherung, Weitergabe, Beschaffung, Löschung, Aufbewahrung, Veränderung, Vernichtung und
                        Verwendung personenbezogener Daten.<br/>
                        <br/>
                        Wir verarbeiten personenbezogene Daten in Übereinstimmung mit dem Schweizer Datenschutzrecht.
                        Sofern
                        und soweit die EU-DSGVO anwendbar ist, verarbeiten wir personenbezogene Daten darüber hinaus auf
                        folgenden Rechtsgrundlagen in Verbindung mit Art. 6 (1) GDPR:<br/>
                        lit. a) Verarbeitung personenbezogener Daten mit Einwilligung der betroffenen Person.<br/>
                        lit. b) Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrages mit der betroffenen
                        Person sowie zur Durchführung entsprechender vorvertraglicher Maßnahmen.<br/>
                        lit. c) Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung, der
                        wir
                        nach geltendem Recht der EU oder nach geltendem Recht eines Landes, in dem die GDPR ganz oder
                        teilweise anwendbar ist, unterliegen.<br/>
                        lit. d) Verarbeitung personenbezogener Daten zur Wahrung lebenswichtiger Interessen der
                        betroffenen
                        Person oder einer anderen natürlichen Person.<br/>
                        lit. f) Verarbeitung personenbezogener Daten zur Wahrung der berechtigten Interessen von uns
                        oder
                        von Dritten, sofern nicht die Grundfreiheiten und Rechte und Interessen der betroffenen Person
                        überwiegen. Zu den berechtigten Interessen gehören insbesondere unser geschäftliches Interesse,
                        unsere Website bereitstellen zu können, die Informationssicherheit, die Durchsetzung eigener
                        Rechtsansprüche und die Einhaltung des schweizerischen Rechts.<br/>
                        <br/>
                        Wir verarbeiten personenbezogene Daten für die Dauer, die für den jeweiligen Zweck oder die
                        jeweiligen Zwecke erforderlich ist. Bei längerfristigen Aufbewahrungspflichten aufgrund
                        gesetzlicher
                        und anderer Verpflichtungen, denen wir unterliegen, schränken wir die Bearbeitung entsprechend
                        ein.<br/>
                    </p>
                    <h2 className="text-xl font-bold my-5 uppercase">Cookies</h2>
                    <p>
                        Diese Website verwendet Cookies. Dabei handelt es sich um kleine Textdateien, die es
                        ermöglichen,
                        spezifische, auf den Nutzer bezogene Informationen auf dem Endgerät des Nutzers zu speichern,
                        während der Nutzer die Website nutzt. Cookies ermöglichen es insbesondere, die
                        Nutzungshäufigkeit
                        und die Anzahl der Nutzer der Seiten zu ermitteln, Verhaltensmuster der Seitennutzung zu
                        analysieren,
                        aber auch, unser Angebot kundenfreundlicher zu gestalten. Cookies bleiben über das Ende einer
                        Browser-Sitzung
                        hinaus gespeichert und können bei einem erneuten Besuch der Seite wieder abgerufen werden. Wenn
                        Sie
                        dies nicht
                        wünschen, sollten Sie Ihren Internet-Browser so einstellen, dass er die Annahme von Cookies
                        verweigert.<br/>
                        <br/>
                        Ein genereller Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken kann
                        für eine Vielzahl der Dienste, insbesondere beim Tracking, über die US-Seite
                        http://www.aboutads.info/choices/
                        oder die EU-Seite http://www.youronlinechoices.com/ erklärt werden. Darüber hinaus kann die
                        Speicherung von
                        Cookies durch Deaktivierung in den Browsereinstellungen erreicht werden. Bitte beachten Sie,
                        dass in
                        diesem Fall
                        nicht alle Funktionen dieses Online-Angebots genutzt werden können.<br/>
                    </p>
                    <h2 className="text-xl font-bold my-5 uppercase">Mit SSL/TLS-Verschlüsselung</h2>
                    <p>
                        Diese Website verwendet aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
                        Inhalte, wie z.B. Anfragen, die Sie an uns als Seitenbetreiber senden, eine
                        SSL/TLS-Verschlüsselung.
                        Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
                        ""http://"" auf ""https://"" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.<br/>
                        <br/>
                        Wenn die SSL- oder TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
                        übermitteln,
                        nicht von Dritten gelesen werden.<br/>
                    </p>
                    <h2 className="text-xl font-bold my-5 uppercase">Rechte der betroffenen Person</h2>
                    <h3 className="text-lg font-bold my-5">Recht auf Bestätigung</h3>
                    <p>
                        Jede betroffene Person hat das Recht, vom Betreiber der Website eine Bestätigung darüber zu
                        verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden. Wenn Sie dieses
                        Bestätigungsrecht ausüben möchten, können Sie sich jederzeit an den Datenschutzbeauftragten
                        wenden. </p>
                    <h3 className="text-lg font-bold my-5">Auskunftsrecht</h3>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, vom Betreiber
                        dieser Website jederzeit unentgeltlich Auskunft über die zu ihrer Person gespeicherten Daten und
                        eine Kopie dieser Auskunft zu erhalten. Darüber hinaus kann ggf. Auskunft über Folgendes erteilt
                        werden: Zwecke der VerarbeitungKategorien der verarbeiteten personenbezogenen DatenEmpfänger, an
                        die
                        die personenbezogenen Daten weitergegeben wurden oder werdenwenn möglich, die geplante Dauer der
                        Speicherung der personenbezogenen Daten oder, falls dies nicht möglich ist, die Kriterien für
                        die
                        Festlegung dieser Dauerdas Bestehen eines Rechts auf Berichtigung oder Löschung der sie
                        betreffenden
                        personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den für die Verarbeitung
                        Verantwortlichen oder ein Recht auf Widerspruch gegen eine solche Verarbeitungdas Bestehen eines
                        Beschwerderechts bei einer Aufsichtsbehördewenn die personenbezogenen Daten nicht bei der
                        betroffenen Person erhoben werden: Alle verfügbaren Informationen über die Herkunft der
                        Daten.<br/>
                        Außerdem hat die betroffene Person das Recht, darüber informiert zu werden, ob personenbezogene
                        Daten in ein Drittland oder an eine internationale Organisation übermittelt worden sind. Ist
                        dies
                        der Fall, so hat die betroffene Person außerdem das Recht, Auskunft über die geeigneten
                        Garantien im
                        Zusammenhang mit der Übermittlung zu erhalten.<br/>
                        Wenn Sie von diesem Auskunftsrecht Gebrauch machen möchten, können Sie sich jederzeit an unseren
                        Datenschutzbeauftragten wenden.<br/>
                    </p>
                    <h3 className="text-lg font-bold my-5">Bereitstellung vorgeschrieben oder erforderlich:</h3>
                    <p>
                        Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch
                        vertraglich
                        vorgeschrieben. Ohne diese Daten ist jedoch der Dienst und die Funktionsfähigkeit unserer
                        Website
                        nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder
                        eingeschränkt
                        sein.
                    </p>
                    <h3 className="text-lg font-bold my-5">Recht auf Berichtigung</h3>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die
                        unverzügliche
                        Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Darüber hinaus
                        hat
                        die betroffene Person das Recht, unter Berücksichtigung der Zwecke der Verarbeitung, die
                        Vervollständigung unvollständiger personenbezogener Daten - auch mittels einer ergänzenden
                        Erklärung
                        - zu verlangen.<br/>
                        Wenn Sie dieses Recht auf Berichtigung ausüben möchten, können Sie sich jederzeit an unseren
                        Datenschutzbeauftragten wenden.<br/>
                    </p>
                    <h3 className="text-lg font-bold my-5">Recht auf Löschung (Recht auf Vergessenwerden)</h3>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem für
                        die
                        Verarbeitung Verantwortlichen dieser Website die unverzügliche Löschung der sie betreffenden
                        personenbezogenen Daten zu verlangen, sofern einer der folgenden Gründe zutrifft und die
                        Verarbeitung nicht mehr erforderlich ist:<br/>
                        Die personenbezogenen Daten wurden für Zwecke erhoben oder auf sonstige Weise verarbeitet, für
                        die
                        sie nicht mehr erforderlich sind.<br/>
                        Die betroffene Person widerruft die Einwilligung, auf der die Verarbeitung beruhte, und es gibt
                        keine andere Rechtsgrundlage für die Verarbeitung<br/>
                        Die betroffene Person legt aus Gründen, die sich aus ihrer besonderen Situation ergeben,
                        Widerspruch
                        gegen die Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für die
                        Verarbeitung
                        vor, oder die betroffene Person legt im Falle von Direktwerbung und damit verbundenem Profiling
                        Widerspruch gegen die Verarbeitung ein<br/>
                        Die personenbezogenen Daten wurden unrechtmäßig verarbeitet<br/>
                        Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach
                        dem
                        Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der für die Verarbeitung
                        Verantwortliche unterliegt<br/>
                        Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft
                        erhoben, die direkt an ein Kind gerichtet sind<br/>
                        Wenn einer der oben genannten Gründe zutrifft und Sie die Löschung von personenbezogenen Daten,
                        die
                        beim Betreiber dieser Website gespeichert sind, veranlassen möchten, können Sie sich jederzeit
                        an
                        unseren Datenschutzbeauftragten wenden. Der Datenschutzbeauftragte dieser Website wird
                        veranlassen,
                        dass dem Löschverlangen unverzüglich nachgekommen wird.<br/>
                    </p>
                    <h3 className="text-lg font-bold my-5">Recht auf Einschränkung der Verarbeitung</h3>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem für
                        die
                        Verarbeitung Verantwortlichen dieser Website die Einschränkung der Verarbeitung zu verlangen,
                        wenn
                        eine der folgenden Bedingungen erfüllt ist:<br/>
                        Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und zwar
                        für
                        einen Zeitraum, der es dem für die Verarbeitung Verantwortlichen ermöglicht, die Richtigkeit der
                        personenbezogenen Daten zu überprüfen<br/>
                        Die Verarbeitung ist unrechtmäßig, die betroffene Person legt Widerspruch gegen die Löschung der
                        personenbezogenen Daten ein und verlangt stattdessen die Einschränkung der Nutzung der
                        personenbezogenen Daten<br/>
                        Der für die Verarbeitung Verantwortliche benötigt die personenbezogenen Daten nicht mehr für die
                        Zwecke der Verarbeitung, die betroffene Person benötigt sie jedoch für die Geltendmachung, Die
                        betroffene Person hat aus Gründen, die sich aus ihrer besonderen Situation ergeben, Widerspruch
                        gegen die Verarbeitung eingelegt, und es steht noch nicht fest, ob die berechtigten Interessen
                        des
                        Verantwortlichen gegenüber denen der betroffenen Person überwiegen<br/>
                        Wenn eine der vorgenannten Voraussetzungen gegeben ist, können Sie sich jederzeit an unseren
                        Datenschutzbeauftragten wenden, um die Einschränkung der Verarbeitung personenbezogener Daten
                        beim
                        Betreiber dieser Website zu verlangen. Der Datenschutzbeauftragte dieser Website wird die
                        Einschränkung der Verarbeitung veranlassen.<br/>
                    </p>
                    <h3 className="text-lg font-bold my-5">Recht auf Datenübertragbarkeit</h3>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die sie
                        betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren
                        Format
                        zu erhalten. Darüber hinaus hat die betroffene Person das Recht, zu erwirken, dass die
                        personenbezogenen Daten direkt von einem für die Verarbeitung Verantwortlichen an einen anderen
                        für
                        die Verarbeitung Verantwortlichen übermittelt werden, sofern dies technisch machbar ist und
                        sofern
                        dadurch nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.<br/>
                        Um das Recht auf Datenübertragbarkeit geltend zu machen, können Sie sich jederzeit an den vom
                        Betreiber dieser Website benannten Datenschutzbeauftragten wenden.<br/>
                    </p>
                    <h3 className="text-lg font-bold my-5">Ein Widerspruchsrecht</h3>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, aus Gründen,
                        die
                        sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender
                        personenbezogener Daten Widerspruch einzulegen.<br/>
                        Der Betreiber dieser Website wird die personenbezogenen Daten im Falle des Widerspruchs nicht
                        mehr
                        verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
                        nachweisen,
                        die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die
                        Verarbeitung
                        dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.<br/>
                        Um von Ihrem Widerspruchsrecht Gebrauch zu machen, können Sie sich direkt an den
                        Datenschutzbeauftragten dieser Website wenden.<br/>
                        Recht auf Widerruf einer datenschutzrechtlichen Einwilligung<br/>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, eine erteilte
                        Einwilligung in die Verarbeitung personenbezogener Daten jederzeit zu widerrufen.<br/>
                        Wenn Sie von Ihrem Recht auf Widerruf einer Einwilligung Gebrauch machen möchten, können Sie
                        sich
                        jederzeit an unseren Datenschutzbeauftragten wenden.<br/>
                    </p>
                    <h3 className="text-lg font-bold my-5">Urheberrechte</h3>
                    <p>
                        Das Urheberrecht und alle anderen Rechte an den Inhalten, Bildern, Fotos oder sonstigen Dateien
                        auf
                        der Website gehören ausschließlich dem Betreiber dieser Website oder den namentlich genannten
                        Rechteinhabern. Für die Vervielfältigung sämtlicher Dateien muss vorab die schriftliche
                        Zustimmung
                        der Urheberrechtsinhaber eingeholt werden.<br/>
                        Wer ohne Zustimmung der jeweiligen Urheberrechtsinhaber eine Urheberrechtsverletzung begeht,
                        kann
                        sich strafbar machen und unter Umständen Schadenersatzansprüche geltend machen.<br/>
                    </p>
                    <h3 className="text-lg font-bold my-5">Haftungsausschluss</h3>
                    <p>
                        Alle Angaben auf unserer Website wurden sorgfältig geprüft. Wir sind bemüht, dafür Sorge zu
                        tragen,
                        dass die von uns bereitgestellten Informationen aktuell, richtig und vollständig sind. Dennoch
                        ist
                        das Auftreten von Fehlern nicht völlig auszuschließen, so dass wir für die Vollständigkeit,
                        Richtigkeit und Aktualität der Informationen, auch journalistisch-redaktioneller Art, keine
                        Gewähr
                        übernehmen können. Haftungsansprüche, die sich auf Schäden materieller oder ideeller Art
                        beziehen,
                        welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung
                        fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich
                        ausgeschlossen.<br/>
                        Der Herausgeber kann Texte nach eigenem Ermessen und ohne vorherige Ankündigung ändern oder
                        löschen
                        und ist nicht dazu verpflichtet, die Inhalte dieser Website zu aktualisieren. Die Nutzung dieser
                        Website bzw. der Zugang zu ihr erfolgt auf eigenes Risiko des Besuchers. Der Herausgeber, seine
                        Kunden oder Partner sind nicht verantwortlich für Schäden, wie z.B. direkte, indirekte,
                        zufällige
                        oder Folgeschäden, die angeblich durch den Besuch dieser Website verursacht wurden und
                        übernehmen
                        folglich keine Haftung für solche Schäden.<br/>
                        Der Herausgeber übernimmt auch keine Verantwortung oder Haftung für den Inhalt und die
                        Verfügbarkeit
                        von Websites Dritter, die über externe Links von dieser Website aus erreicht werden können. Für
                        den
                        Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Der Herausgeber
                        distanziert sich daher ausdrücklich von allen fremden Inhalten, die möglicherweise straf- oder
                        haftungsrechtlich relevant sind oder gegen die guten Sitten verstoßen.<br/>
                    </p>
                    <h2 className="text-xl font-bold my-5 uppercase">Abrufen von Daten</h2>
                    <p>
                        Beim jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und
                        Informationen
                        vom Computersystem des aufrufenden Rechners. Es wird die IP-Adresse des Nutzers abgerufen um
                        einen
                        Wetterdienst anzubieten.
                    </p>
                    <hr className="my-5 border-t-4 rounded-full"/>
                    <h2 className="text-xl font-bold my-5 uppercase">Haftungsausschluss</h2>
                    <p>
                        Der Autor übernimmt keine Gewähr für die Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit
                        und
                        Vollständigkeit der Informationen.<br/>
                        Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, die aus dem
                        Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch
                        der
                        Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.<br/>
                        <br/>
                        Alle Angebote sind freibleibend. Der Autor behält es sich ausdrücklich vor, Teile der Seiten
                        oder
                        das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
                        Veröffentlichung zeitweise oder endgültig einzustellen.<br/>
                    </p>
                </div>
            </div>
        </>
    );
}

export default Impressum;