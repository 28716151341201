import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {ITeamspeakBot, IUserListen} from "../../types";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import CreateTeamspeakBot from "../../utils/Popups/CreateTeamspeakBot";

interface Props {
}

const Dashboard: React.FC<Props> = () => {
    const {stations} = useSelector((state: any) => state.stations);
    const [listenInfo, setListenInfo] = useState<IUserListen>();

    useEffect(() => {
        const headers = new Headers();
        headers.set("Authorization", "Bearer " + Cookies.get('uXgZvxBcmT'))
        fetch(process.env.REACT_APP_API_URL + '/user/listen', {
            headers: headers,
        }).then(async value => {
            if (value.status !== 200) return;
            value.json().then(value1 => {
                setListenInfo(value1)
            })
        })
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <div className="top">
                <div className="max-w-[1000px] px-5 mx-auto pt-[150px]">
                    <div className={"flex flex-col justify-center items-center"}>
                        <h1 className="text-3xl font-bold" data-aos="fade-up">Zugehört</h1>
                        <h2 className="text-xl font-medium flex items-center" data-aos="fade-up"
                            data-aos-delay="50">{!listenInfo ? <div
                            className={"bg-white animate-pulse rounded-lg w-6 h-4 mr-1"}/> : listenInfo.total} Minuten
                            zugehört</h2>
                    </div>
                    <div className="py-10">
                        {listenInfo?.website.length === 0 && listenInfo.discord.length === 0 ?
                            <>
                                <h1 className="text-center text-xl font-bold">Du hast noch nie zugehört</h1>
                            </>
                            : <></>}
                        <h1 className={`text-center text-2xl pb-5 ${listenInfo?.discord.length === 0 ? "hidden" : ""}`} data-aos="fade-up" data-aos-delay="100">Webseite</h1>
                        <div className="flex flex-row flex-wrap justify-center items-center">
                            {!listenInfo ? <>
                                <div
                                    data-aos="fade-up" data-aos-delay={150}
                                    className="w-52 h-20 relative rounded-xl bg-primary justify-center animate-pulse items-center flex flex-col m-2">
                                    <div className="absolute top-0 left-0 w-full h-full rounded-xl opacity-40"/>
                                    <h1 className="text-xl font-bold text-center relative">
                                        <div className={"bg-white animate-pulse rounded-lg w-16 my-1 h-4 mr-1"}/>
                                    </h1>
                                    <h2 className="text-lg font-medium text-center relative">
                                        <div className={"grid grid-cols-3 w-24"}>
                                            <div
                                                className={"bg-white animate-pulse rounded-lg my-1 h-4 mr-1 col-span-2"}/>
                                            <div
                                                className={"bg-white animate-pulse rounded-lg my-1 h-4 mr-1 col-span-1"}/>
                                        </div>
                                    </h2>
                                </div>
                            </> : <></>}
                            {listenInfo?.website.map((value, index) => {
                                const filter = stations.filter((value1: any) => value1.short_name === value.station);
                                let cover;
                                if (filter.length === 0) {
                                    cover = "";
                                } else {
                                    cover = filter[0].current_playing.covers.coverMedium;
                                }
                                return (
                                    <>
                                        <div
                                            className="w-52 h-20 relative rounded-xl bg-primary justify-center items-center flex flex-col m-2"
                                            data-aos="fade-up" data-aos-delay={100 + 50 * index}>
                                            <div
                                                className="absolute top-0 left-0 w-full h-full rounded-xl opacity-40 bg-cover bg-center"
                                                style={{backgroundImage: 'url(' + (cover) + ')'}}/>
                                            <h1 className="text-xl font-bold text-center relative">{value.station}</h1>
                                            <h2 className="text-lg font-medium text-center relative">{value.time} Minuten</h2>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    {!listenInfo ? <>
                    </> : <>
                        <div className={"py-10" + (listenInfo.discord.length === 0 ? " hidden" : "")}>
                            <h1 className={"text-center text-2xl pb-5"}>Discord</h1>
                            <div className="flex flex-row flex-wrap justify-center items-center">
                                {listenInfo.discord.map(value => {
                                    const filter = stations.filter((value1: any) => value1.short_name === value.station);
                                    let cover;
                                    if (filter.length === 0) {
                                        cover = "";
                                    } else {
                                        cover = filter[0].current_playing.covers.coverMedium;
                                    }
                                    return (
                                        <>
                                            <div
                                                className="w-52 h-20 relative rounded-xl bg-primary justify-center items-center flex flex-col m-2">
                                                <div
                                                    className="absolute top-0 left-0 w-full h-full rounded-xl opacity-40"
                                                    style={{background: 'url(' + (cover) + ')'}}/>
                                                <h1 className="text-xl font-bold text-center">{value.station}</h1>
                                                <h2 className="text-lg font-medium text-center">{value.time} Minuten</h2>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </>}
                    <TeamspeakBotsList/>
                </div>
            </div>
        </>
    )
}


const TeamspeakBotsList: React.FC = () => {
    const [teamspeakBot, setTeamspeakBot] = useState<ITeamspeakBot[]>([])
    const navigate = useNavigate();

    useEffect(() => {
        updateTeamspeakBot();
    }, []);

    const updateTeamspeakBot = () => {
        fetch(process.env.REACT_APP_API_URL + '/musicbot/list', {
            headers: {
                "Authorization": "Bearer " + Cookies.get('uXgZvxBcmT'),
            },
        }).then(async value => {
            if (value.status !== 200) return;
            value.json().then(value1 => {
                setTeamspeakBot(value1)
            })
        })
    }

    return (
        <>
            <div className={"flex flex-col justify-center items-center pt-10"}>
                <h1 className="text-3xl font-bold" data-aos="fade-up" data-aos-delay={50}>Teamspeak Bot's</h1>
                {teamspeakBot.length === 0 ?
                    <h2 className="text-xl font-medium flex items-center" data-aos="fade-up" data-aos-delay={100}>Keine
                        Bot's gefunden</h2> :
                    <>
                        <div className="py-10">
                            <div className="flex flex-row flex-wrap justify-center items-center">
                                {teamspeakBot.map((value, index) => {
                                    return (
                                        <>
                                            <div
                                                className="bg-primary w-80 h-20 rounded-lg m-2 flex flex-col items-center justify-center relative"
                                                data-aos="fade-up" data-aos-delay={100 + 50 * index}>
                                                <div
                                                    className="absolute top-0 left-0 w-full h-full rounded-xl opacity-40 bg-cover bg-center cursor-pointer"
                                                    onClick={() => navigate(`/dashboard/${value.id}/bot/teamspeak`)}
                                                    style={{backgroundImage: 'url(' + (value.thumbnail) + ')'}}/>
                                                <h1 className="text-xl font-bold relative cursor-pointer"
                                                    onClick={() => navigate(`/dashboard/${value.id}/bot/teamspeak`)}>{value.name}</h1>
                                                <h2 className="text-lg text-indigo-200 font-medium cursor-pointer relative hover:text-indigo-400 transition-all duration-500"
                                                    onClick={() => window.open(`ts3server://${value.serverAddress}?port=${value.serverPort}`)}>{value.serverAddress}:{value.serverPort}</h2>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </>
                }
                <CreateTeamspeakBot updateList={updateTeamspeakBot}>
                    <button className={"bg-green-400 py-1 px-2 rounded-lg my-5"} data-aos="fade-up"
                            data-aos-delay={150}>Bot Erstellen
                    </button>
                </CreateTeamspeakBot>
            </div>
        </>
    )
}


export default Dashboard;