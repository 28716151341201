import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationDE from './assets/translations/de.json'
import translationEN from './assets/translations/en.json'

const fallbackLng = ['de'];
const availableLanguages = ['de', 'en'];

const resources = {
    de: {
        translation: translationDE
    },
    en: {
        translation: translationEN
    },
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,
        detection: {
            checkWhitelist: true,
        },
        debug: false,
        whitelist: availableLanguages,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;