import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import Select from 'react-select';
import {User} from "../../types";
import BasicPopup from "./BasicPopup";
import toast from "react-hot-toast";

interface Props {
    children: JSX.Element;
    editUser: User,
    updateUser: Function,
}

const EditUserModal: React.FC<Props> = ({children, editUser, updateUser}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const {t} = useTranslation();
    const [cookies] = useCookies(['uXgZvxBcmT']);
    const [user, setUser] = useState<User>();
    const [userRoles, setUserRoles] = useState([{
        name: "",
        type: "",
    }])

    const close = () => {
        setOpen(false);
    }

    useEffect(() => {
        setUser(editUser);
        const myHeaders = new Headers();
        myHeaders.set("Authorization", "Bearer " + cookies.uXgZvxBcmT)
        fetch(process.env.REACT_APP_API_URL + "/user/roles", {
            headers: myHeaders,
        }).then(value => {
            value.json().then(value1 => setUserRoles(value1));
        })
    }, [editUser])

    const changeHandler = (e: { target: { name: any; value: any; }; }) => {
        if(!user) return;
        setUser({...user, [e.target.name]: e.target.value});
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!user) return;
        const myHeaders = new Headers();
        myHeaders.set("Authorization", "Bearer " + cookies.uXgZvxBcmT)
        myHeaders.append("Content-Type", "application/json");
        fetch(process.env.REACT_APP_API_URL + '/user/update/' + user.id, {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify(user)
        }).then(value => {
            close();
            value.json().then(value1 => {
                if (value.status !== 200) {
                    toast.error(value1.message);
                } else {
                    toast.success(t(value1.message));
                }
                setUser(undefined)
                updateUser();
            })
        })
    }

    const customStyles = {
        control: (base: any, state: { isFocused: any; }) => ({
            ...base,
            background: "#313131",
            borderRadius: "0.5rem",
            borderColor: "none",
            border: "0",
            boxShadow: state.isFocused ? null : null,
        }),
        menu: (base: any) => ({
            ...base,
            borderRadius: "0.5rem",
            marginTop: 0
        }),
        menuList: (base: any) => ({
            ...base,
            borderRadius: "0.5rem",
            backgroundColor: "#313131",
            padding: 0,
            "&:hover": {
                backgroundColor: "#454545"
            }
        })
    };

    return (
        <>
            {React.cloneElement(children, {onClick: () => setOpen(true)})}
            {open ?
                <BasicPopup closeFunction={close} title={"User bearbeiten"} active={open}>
                    <form className="grid lg:grid-cols-2 grid-cols-1 space-x-2 lg:min-w-[25vw] min-w-[75vw]" onSubmit={onSubmit}>
                        <div className="flex flex-col my-2">
                            <label className="text-lg py-1">{t('user.username')}</label>
                            <input name="username" type="text" value={user?.username}
                                   onChange={changeHandler}
                                   className="p-1 rounded-lg bg-secondary"
                                   placeholder={t('admin.defaultName').toString()} required={true}></input>
                        </div>
                        <div className="flex flex-col my-2">
                            <label className="text-lg py-1">{t('user.email')}</label>
                            <input name="email" type="email" value={user?.email}
                                   onChange={changeHandler}
                                   className="p-1 rounded-lg bg-secondary"
                                   placeholder={"info@breathfm.de"} required={true}></input>
                        </div>
                        <div className="flex flex-col my-2">
                            <label className="text-lg py-1">{t('user.status')}</label>
                            <input name="status" type="number" value={user?.status}
                                   onChange={changeHandler}
                                   className="p-1 rounded-lg bg-secondary"
                                   placeholder={"0"} required={true}></input>
                        </div>
                        <div className="flex flex-col my-2">
                            <label className="text-lg py-1">{t('user.role')}</label>
                            <Select
                                isMulti
                                name="colors"
                                styles={customStyles}
                                onChange={newValue => {
                                    if(!user) return;
                                    setUser({
                                        ...user,
                                        role: newValue.map(value => value.value),
                                    })
                                }}
                                value={user?.role.map(value => {
                                    return {
                                        value: value,
                                        label: value.toLowerCase(),
                                    }
                                })}
                                options={userRoles.map(value => {
                                    return {value: value.name, label: value.type}
                                })}
                                className="bg-transparent"
                                classNamePrefix="select"/>
                        </div>
                        <button className="p-1 rounded-lg bg-secondary w-full mx-auto ml-2 mt-5"
                                type={"submit"}>{t("user.save")}</button>
                    </form>
                </BasicPopup>
                : <></>}
        </>
    )
}

export default EditUserModal;
