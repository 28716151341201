import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import BasicPopup from "./BasicPopup";
import {LiveStream} from "../../types";

interface Props {
    isActive: boolean,
    close: () => void,
}

const LiveTimetable: React.FC<Props> = ({isActive, close}) => {
    const [streamers, setStreamers] = useState<LiveStream[]>([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/station/streamers/1').then(value => {
            value.json().then(value1 => {
                if (value1.length !== 0) {
                    value1.sort(function (a: { start: string | number | Date; }, b: {
                        start: string | number | Date;
                    }) {
                        return new Date(a.start).getTime() - new Date(b.start).getTime()
                    })
                    setStreamers(value1)
                }
            })
        })

    }, [])

    const {t} = useTranslation();
    if (!isActive) return (<></>);
    let oldStartDate = 0;
    let showStreamers = 0;

    return (
        <>
        <BasicPopup closeFunction={close} title={t('livetimetable.title')} active={isActive}>
                <div className={"mx-5 my-5"}>
                    {streamers.map((value) => {
                        const startDate = new Date(value.start);
                        const endDate = new Date(value.end);
                        const isNewDate = oldStartDate !== startDate.getUTCDate();
                        if (isNewDate) oldStartDate = startDate.getUTCDate()
                        if (endDate.getTime() <= Date.now()) return (<></>)
                        showStreamers++;
                        return (
                            <>
                                {isNewDate ?
                                    <p className="text-left text-lg font-medium">{('0' + (startDate.getDate())).slice(-2) + "." + ('0' + (startDate.getMonth() + 1)).slice(-2) + "." + startDate.getFullYear()}</p>
                                    : <></>}
                                <div
                                    className="flex flex-row justify-between text-center items-center p-2 my-3 bg-[#525253] border-2 border-[#242323] rounded-xl">
                                    <div className="rounded-full w-24">
                                        <img className="rounded-full w-24" src={value.art} alt={value.title}/>
                                    </div>
                                    <h1 className="text-xl font-bold">{value.title}</h1>
                                    <div className="flex flex-row items-center">
                                        <p>{('0' + (startDate.getHours())).slice(-2) + ":" + ('0' + (startDate.getMinutes())).slice(-2)}</p>
                                        <div className="mx-3 h-[3px] w-4 rounded-full bg-[#FFFFFF]"></div>
                                        <p>{('0' + (endDate.getHours())).slice(-2) + ":" + ('0' + (endDate.getMinutes())).slice(-2)}</p>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>

                {showStreamers === 0 ?
                    <>
                        <h2 style={{
                            paddingTop: "50px",
                            paddingBottom: "50px",
                            fontSize: "22px"
                        }}>{t('homepage.no_livestream_planned')}</h2>
                    </>
                    : <></>}
            </BasicPopup>
        </>
    )
}

export default LiveTimetable;
