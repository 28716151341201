import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    player: {
        isActive: false,
        stationName: "",
        isPlayed: false,
    },
}

export const playerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        changePlayer: (state, action) => {
            state.player = action.payload;
        },
        resetPlayer: (state) => {
            state.player = {
                isActive: false,
                stationName: "",
                isPlayed: false,
            }
        }
    },
});

export const { changePlayer } = playerSlice.actions;

export default playerSlice.reducer;