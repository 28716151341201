import React, {ChangeEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";

interface Props {
}

const RegisterComponent: React.FC<Props> = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [register, setRegister] = useState({
        username: "",
        email: "",
        password: "",
        passwordReplay: "",
        datenschutz: false,
    });

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.type === "checkbox") {
            setRegister({...register, [e.target.name]: e.target.checked});
        } else {
            setRegister({...register, [e.target.name]: e.target.value});
        }
    }

    const registerRequest = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!register.datenschutz) {
            toast.error(t('register.failedNoDatenschutz'));
            return;
        }
        if (register.password !== register.passwordReplay) {
            toast.error(t('register.failedPasswordReplay'));
            return;
        }
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        const urlencoded = new URLSearchParams();
        urlencoded.append("username", register.username);
        urlencoded.append("email", register.email);
        urlencoded.append("password", register.password);
        fetch(process.env.REACT_APP_API_URL + '/user/create', {
            method: 'POST',
            body: urlencoded,
            headers: myHeaders
        }).then(value => {
            console.log(value.status)
            value.json().then(value1 => {
                if (value.status !== 201) {
                    toast.error(value1.message);
                } else {
                    toast.success(value1.message);
                    navigate('/signin')
                }
            })
        });
    }

    return (
        <>
            <div className="top">
                <h1 className="lg:pt-[150px] pt-[120px] text-4xl text-center font-bold pb-10">{t('register.title')}</h1>
                <div className={"xl:w-1/3 w-3/4 mx-auto pb-5"}>
                    <form onSubmit={registerRequest}>
                        <div className={"flex flex-col lg:w-1/2 w-full mx-auto pb-5"}>
                            <label className="text-lg font-medium">{t('username')}</label>
                            <input name="username" type={"text"} className="py-1 bg-secondary px-2 outline-0 resize-none rounded-lg border border-gray-700"
                                   placeholder={"Username"}
                                   onChange={changeHandler} required={true}></input>
                        </div>
                        <div className={"flex flex-col lg:w-1/2 w-full mx-auto pb-5"}>
                            <label className="text-lg font-medium">{t('email')}</label>
                            <input name="email" type={"email"} className="py-1 bg-secondary px-2 outline-0 resize-none rounded-lg border border-gray-700"
                                   placeholder={"info@breathfm.de"}
                                   onChange={changeHandler} required={true}></input>
                        </div>
                        <div className={"flex flex-col lg:w-1/2 w-full mx-auto pb-5"}>
                            <label className="text-lg font-medium">{t('password')}</label>
                            <input name="password" className="py-1 px-2 bg-secondary rounded-lg border border-gray-700" placeholder={"Passwort"}
                                   type="password"
                                   onChange={changeHandler} required={true}></input>
                        </div>
                        <div className={"flex flex-col lg:w-1/2 w-full mx-auto pb-5"}>
                            <label className="text-lg font-medium">{t('passwordReplay')}</label>
                            <input name="passwordReplay" className="py-1 px-2 bg-secondary rounded-lg border border-gray-700" placeholder={"Passwort"}
                                   type="password"
                                   onChange={changeHandler} required={true}></input>
                        </div>
                        <div className="flex items-center lg:w-1/2 w-full mx-auto">
                            <input id="link-checkbox" type="checkbox" name={"datenschutz"} value="false" onChange={changeHandler} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label htmlFor="link-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Datenschutz Akzeptieren</label>
                        </div>
                        <div className={"lg:w-1/2 w-full mx-auto text-center"}>
                            <button type="submit"
                                    className="py-2 px-3 border border-gray-700 rounded-lg mx-auto mt-5 bg-secondary">{t('register.button')}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default RegisterComponent;