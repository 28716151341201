import {configureStore} from '@reduxjs/toolkit';

import playerReducer from '../slice/player/playerSlice';
import stationsSlice from "../slice/stations/stationsSlice";
import userSlice from "../slice/user/userSlice";

const store = configureStore({
    reducer: {
        player: playerReducer,
        stations: stationsSlice,
        user: userSlice,
    },
});

export default store;
