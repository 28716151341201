import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {IPartner} from "../component/types";
import PartnerItemComponent from "../component/partner/PartnerItemComponent";

interface Props {
}

const PartnerPage: React.FC<Props> = () => {
    const {t} = useTranslation();
    const [partner, setPartner] = useState<IPartner[]>([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/partner/all`).then(value => {
            value.json().then(value1 => setPartner(value1));
        })
    }, [])

    return (
        <>
            <div className={"top"}>
                <h1 className="text-3xl font-bold pt-[150px] pb-5 text-center"
                    data-aos="fade-up">{t('header.partner')}</h1>

                <div className={"flex flex-col flex-wrap max-w-[1920px] pt-5 px-10 mx-auto"}>
                    {partner.map((value, index) => {
                            return (
                               <PartnerItemComponent partner={value} index={index}/>
                            )
                        }
                    )}
                </div>

                <h1 className="font-bold text-3xl text-center pt-10" data-aos="fade-up">Interessiert an eine Partnerschaft?</h1>
                <p className="text-center text-lg pt-2" data-aos="fade-up">Dann Besuche uns doch auf unseren Discord oder schreibe eine E-Mail</p>
                <div className="flex justify-center py-5 text-center">
                    <a className="w-32 bg-primary py-2 rounded-lg mx-5 hover:bg-indigo-500 transition-all duration-300" data-aos="fade-up" data-aos-delay="50" href={"http://dc.breathfm.de"}>
                        Discord
                    </a>
                    <a className="w-32 bg-primary py-2 rounded-lg mx-5 hover:bg-yellow-600 transition-all duration-300" data-aos="fade-up" data-aos-delay="100"  href={"mailto:info@breathfm.de"}>
                        E-Mail
                    </a>
                </div>
            </div>
        </>
    )
}


export default PartnerPage;