import {Outlet, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const UserLayout = () => {
    const navigate = useNavigate();
    const {user} = useSelector((state: any) => state.user);


    if(user === null) {
        navigate('/')
    }

    return (
        <>
            <Outlet/>
        </>
    )
}

export default UserLayout;