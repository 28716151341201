import React, {useEffect, useState} from "react";
import {InfoBarComponent} from "../content/dashboard/admin/InfoBarDashboard";
import {IInfobar} from "../types";

const NotificationBar  = () => {
    const [message, setMessage] = useState<IInfobar | null>(null);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/info-bar/').then(value => {
                if (value.status === 200) {
                    value.json().then(value1 => {
                        setMessage(value1)
                    })
                }
            }
        )
    }, []);

    if(!message) return (<></>);

    return (
        <>
            <InfoBarComponent className={"py-2"} infoBar={message}/>
        </>
    )
}

export default NotificationBar;