import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {IHistory} from "../types";

interface Props {
}

const History: React.FC<Props> = () => {
    const {t} = useTranslation();
    const [history, setHistory] = React.useState<IHistory[]>([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/history/all`).then(value => {
            value.json().then(value1 => setHistory(value1));
        })
    }, []);

    return (
        <>
            <div className="top">
                <h1 className="text-3xl font-bold pt-[150px] pb-5 text-center"
                    data-aos="fade-up">{t('history.title')}</h1>
                <div className={"lg:w-[50vw] mx-auto w-[90vw] pb-28 pt-5"}>
                    <div
                        className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-primary before:to-transparent">
                        {history.map((value, index) => {
                            return (
                                <>
                                    <HistoryComponent index={index + 1} title={value.title}
                                                      description={value.description} date={new Date(value.date)}/>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

interface HistoryComponentProps {
    index: number;
    title: string;
    description: string;
    date: Date;
}

const HistoryComponent: React.FC<HistoryComponentProps> = ({index, title, description, date}) => {
    const formatter = new Intl.DateTimeFormat(i18n.language, {month: '2-digit', year: 'numeric', day: '2-digit'})

    return (
        <>
            <div
                className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active"
                data-aos="fade-up" data-aos-delay={index * 50}>
                <div
                    className="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-primary group-[.is-active]:bg-emerald-500 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="12" height="10">
                        <path fillRule="nonzero"
                              d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"/>
                    </svg>
                </div>
                <div
                    className="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-primary p-4 rounded-lg shadow">
                    <div className="flex items-center justify-between space-x-2 mb-1">
                        <div className="font-bold text-slate-300">{title}</div>
                        <time className="font-caveat font-medium text-indigo-100">{formatter.format(date)}</time>
                    </div>
                    <div className="text-slate-400">
                        {description}
                    </div>
                </div>
            </div>
        </>
    )
}

export default History;