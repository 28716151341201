import React from "react";
import NotificationBar from "../utils/NotificationBar";
import Player from "../player/Player";
import LivechatComponent from "../utils/Livechat";
import Header from "./Header";
import {Outlet} from "react-router-dom";
import Footer from "./Footer";
import Snowfall from "react-snowfall";
import Confetti from "react-confetti";

interface DefaultLayoutProps {
    isWinter: boolean;
}

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({isWinter}) => {
    return (
        <>
            {isWinter ?
                <Snowfall radius={[Math.random() + 0.2, Math.random() + 0.5]} wind={[0.001, 0.005]}
                          style={{position: "fixed", zIndex: "4"}}/> : <></>}
            {new Date(Date.now()).getMonth() === 2 && new Date(Date.now()).getDate() === 19 ?
                <Confetti
                    numberOfPieces={25}
                    recycle={true} run={true} className={"fixPosition"}
                /> : <></>}
            <NotificationBar/>
            <Header/>
            <Player/>
            <LivechatComponent/>
            <Outlet />
            <Footer isWinter={isWinter}/>
        </>
    );
}