import React, {useEffect} from "react";

interface Props {
    children: React.ReactNode,
    closeFunction: () => void,
    title: string,
    active: boolean,
}

const BasicPopup: React.FC<Props> = ({children, closeFunction, title, active}) => {
    useEffect(() => {
        if (!active) return;
        const listener = (event: KeyboardEvent) => {
            if (event.key === 'Escape') closeFunction();
        };
        document.addEventListener('keyup', listener);

        return () => {
            if (!active) return;
            document.removeEventListener('keyup', listener);
        };
    }, [active]);
    return (
        <>
            <div className="alert right-0 top-0 z-20 h-screen w-screen absolute p-0 m-0 left-0 bottom-0">
                <div
                    onClick={() => closeFunction()}
                    className="bg-black opacity-40 fixed w-screen h-screen z-10 background top-0 right-0 left-0"
                ></div>
                <div
                    className="fixed flex z-10 justify-center items-center overflow-auto mx-auto w-screen h-screen left-0 top-0"
                    id={"popup-container"}>
                    <div
                        className="bg-primary px-2 py-5 lg:min-w-[25vw] lg:max-w-[75vw] max-h-[75vh] rounded-xl relative block overflow-y-auto">
                        <div className="absolute right-0 pr-5">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                 onClick={() => closeFunction()}
                                 className={"fill-white hover:fill-gray-500 duration-500 cursor-pointer"}
                                 viewBox="0 0 384 512">
                                <path
                                    d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/>
                            </svg>
                        </div>
                        <h1 className="text-2xl font-bold py-5 text-center">{title}</h1>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
}

export default BasicPopup;