import React from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import BasicPopup from "./BasicPopup";
import {HistoryPreviewComponent} from "../../content/dashboard/admin/HistoryDashboard";

interface Props {
    children: JSX.Element;
    history: {
        id: number;
        title: string;
        description: string;
        date: Date;
    };
    updateHistory: () => void;
}


const DeleteHistory: React.FC<Props> = ({children, updateHistory, history}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const {t} = useTranslation();
    const close = () => {
        setOpen(false);
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!history) return;
        fetch(process.env.REACT_APP_API_URL + '/history/' + history.id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            },
            body: JSON.stringify(history),
        }).then((value) => {
            if (value.status !== 200) {
                toast.error(t('history.deleteError'));
            } else {
                toast.success(t('history.deleteSuccess'));
                updateHistory();
            }
            close();
        })
    }

    return (
        <>
            {React.cloneElement(children, {onClick: () => setOpen(true)})}
            {open ?
                <BasicPopup closeFunction={close} title={"Item Löschen"} active={open}>
                    <HistoryPreviewComponent date={history.date} title={history.title} description={history.description}/>
                    <form onSubmit={onSubmit} className="flex justify-center mt-5">
                        <button className={"bg-red-500 text-white rounded-xl px-5 py-2"} type={"submit"}>Löschen
                        </button>
                    </form>
                </BasicPopup>
                : <></>}
        </>
    )
}

export default DeleteHistory;