import React from 'react';

import {NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import Candle from "../utils/Candle";

interface Props {
    isWinter: boolean
}

const Footer: React.FC<Props> = ({isWinter}) => {
    const {t} = useTranslation();
    const date = new Date();


    return (
        <>
            <section className={`bg-primary relative ${isWinter ? "mt-10" : "pt-5"}`}>
                {isWinter ?
                    <div className="flex flex-row flex-nowrap absolute top-[-50px] space-x-4 left-1/2 translate-x-[-50%]">
                        <Candle
                            flame={(date.getMonth() >= 11 && date.getDate() >= 3) && (date.getMonth() <= 11 && date.getDate() <= 27)}/>
                        <Candle
                            flame={(date.getMonth() >= 11 && date.getDate() >= 10) && (date.getMonth() <= 11 && date.getDate() <= 27)}/>
                        <Candle
                            flame={(date.getMonth() >= 11 && date.getDate() >= 17) && (date.getMonth() <= 11 && date.getDate() <= 27)}/>
                        <Candle
                            flame={(date.getMonth() >= 11 && date.getDate() >= 24) && (date.getMonth() <= 11 && date.getDate() <= 27)}/>
                    </div>
                    : <></>}
                <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
                    <nav className="flex flex-wrap justify-center -mx-5 -my-2">
                        {/*<div className="px-5 py-2">
                            <NavLink to="/atmosplus" className="text-base leading-6 text-gray-400 hover:text-gray-200 transition-all duration-500">
                                {t('layout.atmosplus')}
                            </NavLink>
                        </div> */}
                        <div className="px-5 py-2">
                            <NavLink to="/team" className="text-base leading-6 text-gray-400 hover:text-gray-200 transition-all duration-500">
                                {t('layout.team')}
                            </NavLink>
                        </div>
                        <div className="px-5 py-2">
                            <NavLink to="/partner" className="text-base leading-6 text-gray-400 hover:text-gray-200 transition-all duration-500">
                                {t('layout.partner')}
                            </NavLink>
                        </div>
                        <div className="px-5 py-2">
                            <NavLink to="/bots" className="text-base leading-6 text-gray-400 hover:text-gray-200 transition-all duration-500">
                                {t('layout.bots')}
                            </NavLink>
                        </div>
                        <div className="px-5 py-2">
                            <NavLink to="/privacy" className="text-base leading-6 text-gray-400 hover:text-gray-200 transition-all duration-500">
                                {t('layout.privacy')}
                            </NavLink>
                        </div>
                        <div className="px-5 py-2">
                            <NavLink to="/imprint" className="text-base leading-6 text-gray-400 hover:text-gray-200 transition-all duration-500">
                                {t('layout.imprint')}
                            </NavLink>
                        </div>
                    </nav>
                    <div className="flex justify-center mt-8 space-x-6">
                        <a href="https://www.instagram.com/breathfm/" target="_blank" className="text-gray-400 hover:text-gray-500" rel="noreferrer">
                            <span className="sr-only">Instagram</span>
                            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd"></path>
                            </svg>
                        </a>
                        <a href="https://twitter.com/breathfm" target="_blank" className="text-gray-400 hover:text-gray-500" rel="noreferrer">
                            <span className="sr-only">X</span>
                            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                            </svg>
                        </a>
                        <a href="https://www.tiktok.com/@breathfmde" target="_blank" className="text-gray-400 hover:text-gray-500" rel="noreferrer">
                            <span className="sr-only">TikTok</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 448 512"
                                 className="w-6 h-6"
                                 fill="currentColor">
                                <path
                                    d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
                            </svg>
                        </a>
                        <a href="http://dc.breathfm.de" target="_blank" className="text-gray-400 hover:text-gray-500" rel="noreferrer">
                            <span className="sr-only">Discord</span>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 640 512"
                                 className="w-6 h-6"
                                 fill="currentColor">
                                <path
                                    d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"/>
                            </svg>
                        </a>
                    </div>
                    <p className="mt-8 text-base leading-6 text-center text-gray-400">
                        © {new Date().getFullYear()} BreathFM. All rights reserved.
                    </p>
                </div>
            </section>
        </>
    )
}

export default Footer;