import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import toast from 'react-hot-toast';
import {useNavigate} from "react-router-dom";

interface Props {
}

const ForgetPasswordComponent: React.FC<Props> = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [forgetPassword, setForgetPassword] = useState({
        email: "",
    });

    const changeHandler = (e: { target: { name: any; value: any; }; }) => {
        setForgetPassword({...forgetPassword, [e.target.name]: e.target.value});
    }

    const forgetPasswordRequest = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        const urlencoded = new URLSearchParams();
        urlencoded.append("email", forgetPassword.email);
        fetch(process.env.REACT_APP_API_URL + '/user/requestPasswordChange', {
            method: 'POST',
            body: urlencoded,
            headers: myHeaders
        }).then(value => {
            console.log("ee")
            value.json().then(value1 => {
                if (value.status !== 200) {
                    toast.error(value1.message,
                        {
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );
                } else {
                    toast.success(t(value1.message),
                        {
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );
                    setTimeout(() => {
                        navigate("/")
                    }, 5000)
                }
            })
        })
    }


    return (
        <>
            <div className="top">
                <h1 className="lg:pt-[150px] pt-[120px] text-4xl text-center font-bold pb-10">{t('forgetPassword.title')}</h1>
                <div className={"xl:w-1/3 w-3/4 mx-auto pb-5"}>
                    <form onSubmit={(event) => forgetPasswordRequest(event)}>
                        <div className={"flex flex-col lg:w-1/2 w-full mx-auto pb-5"}>
                            <label className="text-lg font-medium">{t('forgetPassword.email')}</label>
                            <input name="email" type={"email"} className="py-1 bg-secondary px-2 outline-0 resize-none rounded-lg border border-gray-800"
                                   placeholder={"info@breathfm.de"}
                                   onChange={event => changeHandler(event)} required={true}></input>
                        </div>
                        <div className={"lg:w-1/2 w-full mx-auto text-center"}>
                            <button type="submit"
                                    className="py-2 px-3 border border-gray-800 rounded-lg mx-auto mt-5 bg-secondary">{t('forgetPassword.resetPassword')}</button>
                        </div>
                    </form>
                </div>
                <div className="text-center pt-5">
                    <p onClick={() => navigate('/signin')} className={"cursor-pointer w-auto"}>{t('forgetPassword.backToLogin')}</p>
                </div>
            </div>
        </>
    )
}

export default ForgetPasswordComponent;