import React, {useEffect, useState} from "react";
import Cookies from "js-cookie";
import DeleteInfobar from "../../../utils/Popups/DeleteInfobar";
import {IInfobar} from "../../../types";
import CreateInfobar from "../../../utils/Popups/CreateInfobar";


const InfoBarDashboard = () => {
    const [infoBars, setInfobars] = useState<IInfobar[]>([]);
    const updateInfoBar = () => {
        fetch(`${process.env.REACT_APP_API_URL}/info-bar/all`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            },
        }).then(value => {
            value.json().then(value1 => setInfobars(value1));
        });
    }

    useEffect(() => {
        updateInfoBar()
    }, []);

    const format = new Intl.DateTimeFormat('de-de', {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit"
    })

    return (
        <>
            <div className="min-h-[85vh]">
                <h1 className="text-3xl font-bold pt-[150px] text-center pb-5">Info Bar</h1>
                <div className="flex justify-center">
                    <CreateInfobar updateInfoBar={updateInfoBar}>
                        <button className={"bg-primary mx-auto text-white rounded-xl px-5 py-2"}>Neuen Eintrag
                            hinzufügen
                        </button>
                    </CreateInfobar>
                </div>
                <div className={"flex flex-row flex-wrap pt-10 items-center w-full justify-center"}>
                    {infoBars.map(value => {
                        return (
                            <>
                                <div className={"w-96 bg-secondary rounded-lg px-5 py-2"}>
                                    <InfoBarComponent className="rounded-lg" infoBar={value}/>
                                    <div className="">
                                        <h1>Start Datum: {format.format(new Date(value.startDate.toString()))}</h1>
                                        <h1>End Datum: {format.format(new Date(value.endDate.toString()))}</h1>
                                    </div>
                                    <DeleteInfobar updateInfoBar={updateInfoBar} infoBar={value}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14"
                                             className={"fill-red-500 hover:fill-red-600 cursor-pointer mx-2 transition-all duration-500"}
                                             viewBox="0 0 448 512">
                                            <path
                                                d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                                        </svg>
                                    </DeleteInfobar>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

interface InfoBarComponentProps {
    infoBar: IInfobar;
    className?: string;
}

export const InfoBarComponent: React.FC<InfoBarComponentProps> = ({infoBar, className}) => {
    return (
        <>
            <div className={`block w-full ${className}`} style={{backgroundColor: infoBar.color, color: infoBar.textColor}}>
                <p className="m-0 px-2 text-center text-[17px]"><b>{infoBar.title}</b> {infoBar.description}</p>
            </div>
        </>
    )
}

export default InfoBarDashboard;