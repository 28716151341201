import React from "react";
import {useTranslation} from "react-i18next";
import BasicPopup from "./BasicPopup";

interface Props {
    isActive: boolean,
    lastSongs: Array<any>,
    close: () => void,
}

const LastSongs: React.FC<Props> = ({lastSongs, isActive, close}) => {
    const {t} = useTranslation();
    if (!isActive) return (<></>);
    return (
        <>
            <BasicPopup closeFunction={close} title={t('lastsong.title')} active={isActive}>
                <div className="flex flex-row justify-center flex-wrap items-center">
                    {lastSongs.map((value) => {
                        return (<>
                            <div className="w-80 m-5">
                                <div className="h-80 w-80 rounded-xl"
                                     style={{
                                         backgroundImage: "linear-gradient(rgba(255,255,255,0), rgba(0,0,0,0.77)), url(" + (value !== null ? value.covers.coverMedium : "https://cdn.breathfm.de/covers/no-cover.jpg") + ")",
                                         backgroundPosition: "center top",
                                         backgroundSize: "cover"
                                     }}>
                                    <div className={"flex flex-col justify-center items-center w-80 text-center"}>
                                        <div className={""}>
                                            <h1
                                                className="pt-[100px] w-80 mt-[10px] text-[30px] mx-auto px-0 font-bold"
                                                style={{
                                                    width: "300px",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap"
                                                }}>{value.title ?? ""}</h1>
                                            <p style={{
                                                width: "300px",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap"
                                            }}
                                               className={"mt-[2px] font-normal mx-auto text-[20px]"}>{value.artist ?? ""}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)
                    })}
                </div>
            </BasicPopup>
        </>
    )
}

export default LastSongs;