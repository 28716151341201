import React, {useEffect, useState} from "react";
import {Station} from "../types";
import {useDispatch, useSelector} from "react-redux";
import {changePlayer} from "../../features/slice/player/playerSlice";

interface Props {
    station: Station,
    playlist?: boolean,
    setLastSongs: Function,
    isWinter: boolean,
}

const StationCard: React.FC<Props> = ({
                                          station,
                                          playlist,
                                          setLastSongs,
                                          isWinter
                                      }) => {
    const {player} = useSelector((state: any) => state.player);
    const dispatch = useDispatch();

    function onClick() {
        if (station.is_online) {
            console.log(station)
            dispatch(changePlayer({
                isActive: true,
                stationName: station.short_name,
                isPlaying: true,
            }))
        }
    }

    const [playlistName, setPlaylistName] = useState<string>("");
    const [hasPlaylist, setHasPlaylist] = useState<boolean>(false);

    useEffect(() => {
        updatePlaylist();
    }, [station])

    function updatePlaylist() {
        if (playlist && station.short_name !== undefined && !hasPlaylist) {
            setHasPlaylist(true);
            fetch(process.env.REACT_APP_API_URL + '/station/playlist/' + station.short_name).then(value => value.json().then(value1 => {
                setPlaylistName(value1[0].playlistName)
            }))
        }
    }

    try {
        return (
            <>
                <p className="text-center text-[19px] font-semibold">{playlistName}</p>
                <div className="w-80 h-80 flex mx-auto items-center justify-center relative">
                    <div className="w-80 h-80 blur-lg absolute rounded-xl cover" style={{
                        width: "100%",
                        height: "100%",
                        filter: "blur(10px)",
                        zIndex: 1,
                        position: "absolute",
                        backgroundImage: "linear-gradient(rgba(255,255,255,0), rgba(0,0,0,0.77)), url(" + (station.current_playing !== null ? station.current_playing.covers.coverMedium : "https://cdn.breathfm.de/covers/no-cover.jpg") + ")",
                        backgroundPosition: "center top",
                        backgroundSize: "cover",
                    }}/>
                    <div className={"w-80 h-80 text-center z-10 bg-cover bg-center rounded-xl transition-all duration-1000"}
                         style={{backgroundImage: "linear-gradient(rgba(255,255,255,0), rgba(0,0,0,0.77)), url(" + (station.current_playing !== null ? station.current_playing.covers.coverMedium : "https://cdn.breathfm.de/covers/no-cover.jpg") + ")"}}
                         onClick={(event) => {
                             if (event.target instanceof SVGPathElement || event.target instanceof SVGSVGElement) {
                                 if (event.target.className.baseVal === "pathLastSongs") {
                                     return;
                                 }
                             }
                             onClick()
                         }}>
                        {isWinter ?
                            <ul className="lightrope">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                            : <></>}
                        <p className="w-14 absolute ml-[10px] mt-[10px] fill-[#BC1515FF]">{station.live.is_live ?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path
                                    d="M224 0C100.3 0 0 100.3 0 224c0 92.22 55.77 171.4 135.4 205.7c-3.48-20.75-6.17-41.59-6.998-58.15C80.08 340.1 48 285.8 48 224c0-97.05 78.95-176 176-176s176 78.95 176 176c0 61.79-32.08 116.1-80.39 147.6c-.834 16.5-3.541 37.37-7.035 58.17C392.2 395.4 448 316.2 448 224C448 100.3 347.7 0 224 0zM224 312c-32.88 0-64 8.625-64 43.75c0 33.13 12.88 104.3 20.62 132.8C185.8 507.6 205.1 512 224 512s38.25-4.375 43.38-23.38C275.1 459.9 288 388.8 288 355.8C288 320.6 256.9 312 224 312zM224 280c30.95 0 56-25.05 56-56S254.1 168 224 168S168 193 168 224S193 280 224 280zM368 224c0-79.53-64.47-144-144-144S80 144.5 80 224c0 44.83 20.92 84.38 53.04 110.8c4.857-12.65 14.13-25.88 32.05-35.04C165.1 299.7 165.4 299.7 165.6 299.7C142.9 282.1 128 254.9 128 224c0-53.02 42.98-96 96-96s96 42.98 96 96c0 30.92-14.87 58.13-37.57 75.68c.1309 .0254 .5078 .0488 .4746 .0742c17.93 9.16 27.19 22.38 32.05 35.04C347.1 308.4 368 268.8 368 224z"/>
                            </svg> : <></>}</p>
                        <h1
                            className="pt-[100px] w-80 mt-[10px] text-[30px] mx-auto px-0 font-bold"
                            style={{
                            width: "300px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap"
                        }}>{station.current_playing !== null ? station.current_playing.title : ""}</h1>
                        <p style={{
                            width: "300px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap"
                        }} className={"mt-[2px] font-normal mx-auto text-[20px]"}>{station.current_playing !== null ? station.current_playing.artist : ""}</p>
                        {station.is_online ? <>
                                <div className="mx-auto w-full p-[20px]">
                                    {station.short_name === player.stationName ?
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 512 512"
                                             style={{padding: "7px"}}
                                             className="w-[50px] mx-auto animate-spin"
                                             fill={"#FFFFFF"}
                                             width={'50px'}>
                                            <path
                                                d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM80.72 256H79.63c-9.078 0-16.4-8.011-15.56-17.34C72.36 146 146.5 72.06 239.3 64.06C248.3 63.28 256 70.75 256 80.09c0 8.35-6.215 15.28-14.27 15.99C164.7 102.9 103.1 164.3 96.15 241.4C95.4 249.6 88.77 256 80.72 256zM256 351.1c-53.02 0-96-43-96-95.1s42.98-96 96-96s96 43 96 96S309 351.1 256 351.1zM256 224C238.3 224 224 238.2 224 256s14.3 32 32 32c17.7 0 32-14.25 32-32S273.7 224 256 224z"/>
                                        </svg>
                                        : <svg fill={"#FFFFFF"} xmlns="http://www.w3.org/2000/svg" className="w-[50px] mx-auto" viewBox="0 0 512 512">
                                            <path
                                                d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z"/>
                                        </svg>}
                                </div>
                            </>
                            :
                            <>
                                <div style={{height: "140px"}}>

                                </div>
                            </>
                        }
                        {station.is_online ? <>
                            <div className="flex justify-center h-[35px]">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 448 512"
                                     fill="white"
                                     onClick={(event) => {
                                         event.preventDefault();
                                         setLastSongs(station.last_songs);
                                     }}
                                     className="h-[35px] cursor-pointer">
                                    <path className="pathLastSongs"
                                          d="M246.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 109.3 361.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160zm160 352l-160-160c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L224 301.3 361.4 438.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3z"/>
                                </svg>
                            </div>
                            <div className="bg-white w-[12rem] rounded-bl-full mx-[0.5rem] transition-all duration-[2500ms] ease-linear h-[4px] absolute bottom-0 rounded-r-full" style={{width: (station.current_playing !== null ? (station.current_playing.elapsed / station.current_playing.duration) * 100 : 100) + "%"}}/>
                        </> : <></>}
                    </div>
                </div>
            </>
        )
    } catch (e) {
        return <></>
    }
}

export default StationCard;