import React, {RefObject, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {User} from "../../types";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import DeleteAccount from "../../utils/Popups/DeleteAccount";

interface Props {

}

const SettingsComponent: React.FC<Props> = () => {
    const {t} = useTranslation();
    const user: User = useSelector((state: any) => state.user.user);
    const [file, setFile] = useState<any>(undefined)
    const [updateUser, setUpdateUser] = useState({
        username: "",
        picture: "",
        public: false,
    })

    useEffect(() => {
        setUpdateUser({
            picture: user?.picture,
            username: user?.username,
            public: user?.public,
        })
    }, [user]);

    const inputOpenFileRef: RefObject<HTMLInputElement> = React.createRef();

    const showOpenFileDlg = () => {
        inputOpenFileRef.current?.click();
    }

    const onSave = () => {
        if(!updateUser.username) {
            return;
        }
        const formData = new FormData()
        formData.append('picture', file)
        formData.append('public', String(updateUser.public))
        formData.append("username", updateUser.username)


        fetch(process.env.REACT_APP_API_URL + '/user', {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            },
            body: formData,
        }).then((value) => {
            if(value.status !== 200) {
                toast.error(t('settings.savingError'));
            } else {
                toast.success(t('settings.savingSuccess'));
            }
        })
    }

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        const { files } = event.target as HTMLInputElement;
        if (files && files.length !== 0) {
            setFile(files[0]);
            updateUser.picture = URL.createObjectURL(files[0]);
        }
    };

    return (
        <>
            <div className={"top"}>
                <div className={"max-w-[1000px] mx-auto px-5 relative pb-5"}>
                    <h1 className="text-3xl font-bold pt-[150px] pb-5 text-center"
                        data-aos="fade-up">{t('settings.title')}</h1>
                    <div className="bg-primary pb-5 px-5 rounded-lg">
                        <h2 className={"text-2xl font-bold py-5 text-center"}>{t('settings.userinformation')}</h2>
                        <div className={"flex lg:flex-row flex-col"}>
                            <div className={"w-36 h-36 flex-shrink-0 block relative group cursor-pointer"}
                                 onClick={showOpenFileDlg}>
                                <img className={"rounded-lg h-full w-full object-cover"} src={updateUser.picture}
                                     alt={`User Image`} id={"picture"}/>
                                <input ref={inputOpenFileRef} type="file" accept="image/png, image/jpeg"
                                       style={{display: "none"}} onChange={handleChange}/>
                                <div className={"w-full h-full absolute top-0 rounded-lg"}>
                                    <div
                                        className={"group-hover:block top-0 hidden w-full h-full transition-all duration-300 ease-in-out"}>
                                        <div
                                            className="bg-secondary group-hover:opacity-40 opacity-0 w-full h-full absolute transition-all duration-500 rounded-lg"/>
                                        <div className={"flex items-center justify-center h-full w-full"}>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 className={"fill-orange-300 hover:fill-orange-400 cursor-pointer mx-2 transition-all duration-500 w-16 h-16"}
                                                 viewBox="0 0 512 512">
                                                <path
                                                    d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"w-full h-full relative flex flex-col justify-center"}>
                                <div className="ml-5 my-1">
                                    <label className={""}>{t('settings.username')}</label>
                                    <input type={"text"} name={"username"} onChange={event => setUpdateUser({...updateUser, username: event.target.value})} value={updateUser.username}
                                           className={"w-full rounded-lg py-1 px-2 bg-blend-darken bg-secondary"}/>
                                </div>
                                <div className={"w-full ml-5 my-1"}>
                                    <label className={""}>{t('settings.userRoles')}</label>
                                    <div className={"flex flex-row flex-wrap w-full "}>
                                        {user?.role.map(value => {
                                            return (
                                                <>
                                                    <div className="bg-gray-500 py-0.5 px-2 rounded-lg mr-1">
                                                        {value}
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="ml-5 my-1 flex flex-row">
                                    <input type={"checkbox"} name={"public"} onChange={event => setUpdateUser({
                                        ...updateUser,
                                        public: event.target.checked
                                    })} checked={updateUser.public}
                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label htmlFor="link-checkbox"
                                           className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{t('settings.profilePublic')}</label>
                                </div>
                            </div>
                        </div>
                        <button
                            className="w-full bg-green-900 hover:bg-green-950 duration-300 transition-all mt-5 py-2 rounded-lg"
                            onClick={onSave}>{t('settings.save')}</button>
                    </div>
                </div>

                <div className={"max-w-[1000px] mx-auto px-5 relative pb-5"}>
                    <h1 className="text-3xl font-bold pt-[150px] pb-5 text-center"
                        data-aos="fade-up">{t('settings.accountAction')}</h1>
                    <div className="bg-primary pb-5 px-5 rounded-lg justify-center flex">
                        <DeleteAccount>
                        <button
                            className="w-56 bg-red-900 hover:bg-red-950 duration-300 transition-all mt-5 py-2 rounded-lg">{t('settings.deleteAccount')}</button>
                        </DeleteAccount>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingsComponent;