import React from 'react';
import {NavLink} from "react-router-dom";

interface Props {
}

const AdminDashboard: React.FC<Props> = () => {
    return (
        <>
           <div className="min-h-[85vh] pt-[150px]">
                <NavLink to={'/admin/infobar'}><h1 className="text-2xl text-center">Info Bar</h1></NavLink>
               <NavLink to={'/admin/history'}><h1 className="text-2xl text-center">History</h1></NavLink>
               <NavLink to={'/admin/bot'}><h1 className="text-2xl text-center">Bot</h1></NavLink>
               <NavLink to={'/admin/user'}><h1 className="text-2xl text-center">User</h1></NavLink>
               <NavLink to={'/admin/partner'}><h1 className="text-2xl text-center">Partner (Working...)</h1></NavLink>
           </div>
        </>
    )
}


export default AdminDashboard;