import React, {useEffect, useState} from "react";
import {ITeamspeakBot, User} from "../../../types";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";

interface IBot extends ITeamspeakBot {
    user: User;
}

const BotDashboard = () => {
    const [bots, setBots] = useState<IBot[]>([]);
    const updateHistory = () => {
        fetch(`${process.env.REACT_APP_API_URL}/musicbot/listAll`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            },
        }).then(value => {
            value.json().then(value1 => setBots(value1));
        });
    }

    useEffect(() => {
        updateHistory()
    }, []);

    const navigate = useNavigate();

    return (
        <>
            <div className={"flex flex-col items-center max-w-[1920px] mx-auto px-5 min-h-[85vh]"}>
                <h1 className="text-3xl font-bold pt-[150px]">Teamspeak Bot's</h1>
                {bots.length === 0 ?
                    <h2 className="text-xl font-medium flex items-center">Keine Bot's gefunden</h2> :
                    <>
                        <div className="py-10">
                            <div className="flex flex-row flex-wrap justify-center items-center">
                                {bots.map(value => {
                                    return (
                                        <>
                                            <div
                                                onClick={() => navigate(`/dashboard/${value.id}/bot/teamspeak`)}

                                                className="bg-primary w-80 h-20 rounded-lg m-2 flex flex-col items-center justify-center relative">
                                                <div
                                                    className="absolute top-0 left-0 w-full h-full rounded-xl opacity-40 bg-cover bg-center cursor-pointer"
                                                    style={{backgroundImage: 'url(' + (value.thumbnail) + ')'}}/>
                                                <h1 className="text-xl font-bold relative cursor-pointer">{value.name}</h1>
                                                <h2 className="text-lg text-indigo-200 font-medium cursor-pointer relative hover:text-indigo-400 transition-all duration-500"
                                                    onClick={() => window.open(`ts3server://${value.serverAddress}?port=${value.serverPort}`)}>{value.serverAddress}:{value.serverPort}</h2>
                                                <h3 className="text-md">{!value.user ? "No User" : value.user.username}</h3>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default BotDashboard;