import React, {useEffect, useState} from "react";
import CloudyImage from "../../assets/img/cloudy.svg";
import ClearDayImage from "../../assets/img/clear-day.svg";
import ClearNightImage from "../../assets/img/clear-night.svg";
import OvercastDayImage from "../../assets/img/overcast-day.svg";
import OvercastNightImage from "../../assets/img/overcast-night.svg";
import FogImage from "../../assets/img/fog.svg";
import RainImage from "../../assets/img/rain.svg";
import WindImage from "../../assets/img/wind.svg";
import SleetImage from "../../assets/img/sleet.svg";
import SnowImage from "../../assets/img/snow.svg";
import ThunderstormSnowImage from "../../assets/img/thunderstorms-snow.svg";
import ThunderstormRainImage from "../../assets/img/thunderstorms-rain.svg";
import ThunderstormImage from "../../assets/img/thunderstorms.svg";

interface Props {
}

const Weather: React.FC<Props> = () => {
    const [weather, setWeather] = useState({
        icon: "clear-day",
        temperature: "0.0",
        condition: "",
        city: "BreathFM"
    })

    useEffect(() => {
        updateWeather();
        const interval = setInterval(() => {
            if (window.location.pathname !== "/") clearInterval(interval);
            updateWeather();
        }, 30000)

        function updateWeather() {
            fetch(process.env.REACT_APP_API_URL + '/weather').then(value1 => value1.json()).then(value1 => {
                setWeather({
                    condition: value1.weather.condition,
                    temperature: value1.weather.temperature,
                    icon: value1.weather.icon,
                    city: value1.city,
                })
            })
        }
    }, []);

    return (
        <>
            <section className="w-[300px] h-[75px] mt-[30px] rounded-full bg-[#262525] block relative mx-auto"  data-aos="fade-up" data-aos-delay="150">
                <div className="inline-block">
                    {
                        weather.icon === "cloudy" ? <WeatherIcon alt="Cloudy" src={CloudyImage}/> :
                            weather.icon === "clear-day" ? <WeatherIcon alt="Clear Day" src={ClearDayImage}/> :
                                weather.icon === "clear-night" ?
                                    <WeatherIcon alt="Clear Night" src={ClearNightImage}/> :
                                    weather.icon === "partly-cloudy-day" ?
                                        <WeatherIcon alt="Partly Cloudy Day" src={OvercastDayImage}/> :
                                        weather.icon === "partly-cloudy-night" ?
                                            <WeatherIcon alt="Partly Cloudy Night" src={OvercastNightImage}/> :
                                            weather.icon === "fog" ? <WeatherIcon alt="Fog" src={FogImage}/> :
                                                weather.icon === "wind" && weather.condition === "rain" ?
                                                    <WeatherIcon alt="Rain Wind" src={RainImage}/> :
                                                    weather.icon === "wind" ?
                                                        <WeatherIcon alt="Wind" src={WindImage}/> :
                                                        weather.icon === "rain" ?
                                                            <WeatherIcon alt="Rain" src={RainImage}/> :
                                                            weather.icon === "sleet" ?
                                                                <WeatherIcon alt="sleet" src={SleetImage}/> :
                                                                weather.icon === "snow" ?
                                                                    <WeatherIcon alt="snow" src={SnowImage}/> :
                                                                    weather.icon === "thunderstorm" &&
                                                                    weather.condition === "snow" ?
                                                                        <WeatherIcon alt="thunderstorm"
                                                                                     src={ThunderstormSnowImage}/> :
                                                                        weather.icon === "thunderstorm" &&
                                                                        weather.condition === "rain" ?
                                                                            <WeatherIcon alt="thunderstorm"
                                                                                         src={ThunderstormRainImage}/> :
                                                                            weather.icon === "thunderstorm" ?
                                                                                <WeatherIcon alt="thunderstorm"
                                                                                             src={ThunderstormImage}/> :
                                                                                <></>
                    }
                </div>
                <div className="inline-block align-middle pt-[12px] pl-[15px] absolute">
                    <p className="text-[20px] m-0 p-0 font-bold">{weather.temperature}°C</p>
                    <p className="text-[15px] m-0 p-0 font-normal">{weather.city}</p>
                </div>
            </section>
        </>
    )
}

interface WeatherIconProps {
    src: string,
    alt: string
}

const WeatherIcon: React.FC<WeatherIconProps> = ({src, alt}) => {
    return (
        <>
            <img alt={alt} className="w-[75px] h-[75px] rounded-full bg-[#4C8FDE3D]" src={src}/>
        </>
    )
}

export default Weather;