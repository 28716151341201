import React, {ChangeEvent, useEffect, useState} from "react";
import BasicPopup from "./BasicPopup";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {ITeamspeakBotCreate} from "../../types";
import toast from "react-hot-toast";

interface Props {
    children: JSX.Element;
    updateList: () => void;
}

const CreateTeamspeakBotComponent: React.FC<Props> = ({children, updateList}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const {t} = useTranslation();
    const [bot, setBot] = useState<ITeamspeakBotCreate>({name: "", serverPort: "", serverAddress: "", streamUrlId: ""});
    const close = () => {
        setOpen(false);
    }

    const changeHandler = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setBot({...bot, [e.target.name]: e.target.value});
    }

    const [streamList, setStreamList] = React.useState<any[]>([]);

    useEffect(() => {
        const headers = new Headers();
        headers.set("Authorization", "Bearer " + Cookies.get('uXgZvxBcmT'))
        fetch(process.env.REACT_APP_API_URL + '/musicbot/streamURL/list', {
            headers: headers,
        }).then(async value => {
            if (value.status !== 200) return;
            value.json().then(value1 => {
                setStreamList(value1)
            })
        })
    }, []);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!bot) return;
        fetch(process.env.REACT_APP_API_URL + '/musicbot/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            },
            body: JSON.stringify(bot),
        }).then(async (value) => {
            if (value.status !== 201) {
                toast.error(t((await value.json()).message));
            } else {
                toast.success(t((await value.json()).message));
                updateList();
            }
            close();
        })
    }

    return (
        <>
            {React.cloneElement(children, {onClick: () => setOpen(true)})}
            {open ?
                <BasicPopup closeFunction={close} title={"Bot Erstellen"} active={open}>
                    <div className="flex flex-row flex-wrap w-full my-2">
                        <form className="w-full mr-3" onSubmit={onSubmit}>
                            <div className={"flex md:flex-row flex-col relative"}>
                                <div className={"flex flex-col w-full m-2"}>
                                    <label className="text-lg py-1">{t("teamspeakbots.name")}</label>
                                    <input defaultValue={bot.name} type="text" name="name" className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler}
                                           placeholder={t("teamspeakbots.name").toString()}/>
                                </div>
                            </div>
                            <div className={"flex md:flex-row flex-col w-full relative"}>
                                <div className={"flex flex-col md:w-1/2 w-full m-2"}>
                                    <label className="text-lg py-1">{t("teamspeakbots.serverAddress")}</label>
                                    <input type="text" defaultValue={bot.serverAddress} className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler} required={true}
                                           name={"serverAddress"} placeholder={t("teamspeakbots.serverAddress").toString()}/>
                                </div>
                                <div className={"flex flex-col md:w-1/2 w-full m-2"}>
                                    <label className="text-lg py-1">{t("teamspeakbots.serverPort")}</label>
                                    <input type="number" defaultValue={bot.serverPort} className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler} required={true}
                                           name="serverPort" placeholder={t("teamspeakbots.serverPort").toString()}/>
                                </div>
                            </div>
                            <div className={"w-full relative"}>
                                <div className={"w-full m-2 flex flex-col"}>
                                    <label className="text-lg py-1">{t('teamspeakbots.stream')}</label>
                                    <select name="streamUrlId" className={"bg-secondary rounded-lg p-1 w-full"} onChange={changeHandler} required={true}>
                                        <option defaultChecked={true} disabled={bot?.streamUrlId !== ""}>----
                                        </option>
                                        {streamList.map(value => {
                                            return (
                                                <>
                                                    <option value={value.id}>{value.name}</option>
                                                </>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <button className="p-1 rounded-lg bg-secondary w-full ml-2 mt-5" type={"submit"}>{t("teamspeakbots.save")}</button>
                        </form>
                    </div>
                </BasicPopup>
                : <></>}
        </>
    )
}

export default CreateTeamspeakBotComponent;
