import React from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import BasicPopup from "./BasicPopup";
import {User} from "../../types";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

interface Props {
    children: JSX.Element;
}


const DeleteAccount: React.FC<Props> = ({children}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const {t} = useTranslation();
    const user: User = useSelector((state: any) => state.user.user);
    const navigate = useNavigate();
    const close = () => {
        setOpen(false);
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!user) return;
        fetch(process.env.REACT_APP_API_URL + '/user', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            },
        }).then(async (value) => {
            if (value.status !== 200) {
                toast.error(t((await value.json()).message));
            } else {
                toast.success(t((await value.json()).message));
                navigate("/");
            }
            close();
        })
    }

    return (
        <>
            {React.cloneElement(children, {onClick: () => setOpen(true)})}
            {open ?
                <BasicPopup closeFunction={close} title={"Account Löschen?"} active={open}>
                    <form onSubmit={onSubmit} className="flex flex-col justify-center mt-5">
                        <p className={"mx-auto text-xl px-5 my-5 bg-yellow-600 py-1 text-black font-bold rounded-xl"}>⚠️ {t("settings.deleteUserMessage")}</p>
                        <p className="text-xl text-center my-5 font-bold">{t('settings.deleteUserMessage2')}</p>
                        <button className={"bg-red-500 text-white rounded-xl px-5 py-2"} type={"submit"}>Löschen</button>
                    </form>
                </BasicPopup>
                : <></>}
        </>
    )
}

export default DeleteAccount;