import {Outlet, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const AdminLayout = () => {
    const navigate = useNavigate();
    const {user} = useSelector((state: any) => state.user);


    if(user === null) {
        navigate('/')
    } else if(user !== undefined) {
        if(!user.role.includes('admin')) {
            navigate('/dashboard')
        }
    }

    return (
        <>
            <Outlet/>
        </>
    )
}

export default AdminLayout;