import React, {Ref, useEffect, useRef, useState} from "react";
import ReactHlsPlayer from "../utils/hls-player";
import {useCookies} from "react-cookie";
import PopoutImage from '../../assets/img/popoutPlayer.svg';
import {Station} from "../types";
import {useSelector} from "react-redux";

interface Props {
}

const Player: React.FC<Props> = () => {
    const {player} = useSelector((state: any) => state.player);
    const {stations} = useSelector((state: any) => state.stations);
    const [station, setStation] = useState<Station | undefined>(undefined);

    useEffect(() => {
        const filter = stations.filter((station: Station) => station.short_name === player.stationName);
        if (filter.length === 0) return;
        setStation(filter[0]);
    }, [player, stations]);

    const audioPlayer: Ref<any> = useRef();
    const [playing, setPlaying] = useState(false);
    const [currentVolume, setCurrenVolume] = useState(50);
    const [cookies] = useCookies(['uXgZvxBcmT']);


    useEffect(() => {
        if (!player.isActive) return;
        if (station === undefined) return;
        if ('mediaSession' in navigator) {
            if (!audioPlayer.current.paused) {
                navigator.mediaSession.metadata = new MediaMetadata({
                    title: station.current_playing.title,
                    artist: station.current_playing.artist,
                    artwork: [
                        {
                            src: station.current_playing.covers.coverLarge,
                            sizes: "500x500",
                            type: 'image/jpg'
                        },
                        {
                            src: station.current_playing.covers.coverMedium,
                            sizes: "192x192",
                            type: 'image/jpg'
                        },
                        {
                            src: station.current_playing.covers.coverSmall,
                            sizes: "96x96",
                            type: 'image/jpg'
                        },
                    ]
                });

                let position = parseFloat(String((parseInt(String((new Date().getTime() - new Date(station.current_playing.played_at * 1000).getTime()) / 1000)) / (station.current_playing.duration / 1000) / 1000) * station.current_playing.duration));

                navigator.mediaSession.setActionHandler("pause", () => pause())
                navigator.mediaSession.setActionHandler("play", () => play())

                if (position >= station.current_playing.duration) {
                    position = station.current_playing.duration;
                } else if (position === null || position === undefined || position === 0) {
                    position = 1;
                }

                if (station.current_playing.duration !== 0) {
                    try {
                        navigator.mediaSession.setPositionState({
                            duration: station.current_playing.duration,
                            position: position,
                            playbackRate: 1
                        })
                    } catch (e) {
                    }
                }
            }
        }
    }, [stations, player])

    const [loopId, setLoopId] = useState<NodeJS.Timer>();
    const [isLoop, setIsLoop] = useState(false);

    useEffect(() => {
        if (isLoop) {
            clearInterval(loopId)
        } else {
            setIsLoop(true)
        }
        updateListen();
        const loopIdLocal = setInterval(() => {
            updateListen()
        }, 60000)
        setLoopId(loopIdLocal)

        function updateListen() {
            if (!player.isActive) return;
            if (station === undefined) return;
            if (cookies.uXgZvxBcmT !== null) {
                const myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + cookies.uXgZvxBcmT);
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                const urlencoded = new URLSearchParams();
                urlencoded.append("stationId", player.stationName);

                fetch(process.env.REACT_APP_API_URL + "/user/listen", {
                    method: 'POST',
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: 'follow'
                })
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
            }
        }
    }, [playing, player.isActive])

    const play = () => {
        audioPlayer.current.currentTime = audioPlayer.current.duration - 1;
        audioPlayer.current.play();
    };

    const defaultVolume = () => {
        audioPlayer.current.volume = (currentVolume / 150)
    }

    const pause = () => {
        audioPlayer.current.pause();
    };

    const volume = (value: string) => {
        if (player.isActive) {
            setCurrenVolume(parseFloat(value))
            audioPlayer.current.volume = (parseFloat(value) / 100);
        }
    }

    const openPopoutPlayer = () => {
        audioPlayer.current.pause();
        const location: Location = window.location;
        window.open(`${location.protocol}/player/` + player.stationName, "popOutPlayer", 'toolbar=no,status=no,menubar=no,location=no,scrollbars=no,resizable=no,left=10000, top=10000,height=300,width=750')
    }

    function getPlayer() {
        if(station === undefined) return (<></>)
        const current_playing = station.current_playing;
        return (
            <>
                <ReactHlsPlayer
                    style={{display: "none"}}
                    src={station.m3u_url}
                    autoPlay={true}
                    onPause={() => setPlaying(false)}
                    onPlay={() => {
                        setPlaying(true);
                        defaultVolume();
                    }}
                    playerRef={audioPlayer}
                />

                <div
                    className={"fixed bottom-0 bg-white h-[3px] z-50 rounded-r-xl transition-all duration-[2500ms] ease-linear animate-[playerFadeIn_500ms]"}
                    style={{width: ((current_playing.elapsed / current_playing.duration) * 100) + "%"}}/>
                <div className="w-full fixed h-[80px] bottom-0 bg-[#161616] z-40 animate-[playerFadeIn_500ms_linear]">
                    <div
                        className="w-full max-w-[1920px] mx-auto px-5 flex flex-row flex-nowarp justify-between items-center h-full">
                        <div
                            className={"flex max-[640px]:w-2/3 w-1/3 truncate overflow-x-hidden flex-row items-center justify-start h-full"}>
                            <img id="playerCover" src={current_playing.covers.coverMedium}
                                 className="w-14 rounded-lg h-14" alt={"Cover"}/>
                            <div className={"ml-5"}>
                                <p className="text-lg font-medium" id="playerTitle">{current_playing.title}</p>
                                <p className="text-sm font-light" id="playerArtist">{current_playing.artist}</p>
                            </div>
                            <img className="ml-5 w-5 cursor-pointer" src={PopoutImage} alt={"Popout Window"}
                                 onClick={openPopoutPlayer}/>
                        </div>
                        <div
                            className={"flex items-center max-[640px]:w-1/3 max-[640px]:justify-end justify-center h-full w-1/3"}>
                            {playing ?
                                <div
                                    className="h-10 w-10 flex justify-center cursor-pointer items-center rounded-full bg-white fill-[#161616]"
                                    id="playerIcon" onClick={() => pause()}>
                                    <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 320 512">
                                        <path
                                            d="M272 63.1l-32 0c-26.51 0-48 21.49-48 47.1v288c0 26.51 21.49 48 48 48L272 448c26.51 0 48-21.49 48-48v-288C320 85.49 298.5 63.1 272 63.1zM80 63.1l-32 0c-26.51 0-48 21.49-48 48v288C0 426.5 21.49 448 48 448l32 0c26.51 0 48-21.49 48-48v-288C128 85.49 106.5 63.1 80 63.1z"/>
                                    </svg>
                                </div>
                                :
                                <div
                                    className="h-10 w-10 flex justify-center cursor-pointer ease-in-out items-center rounded-full bg-white fill-[#161616]"
                                    id="playerIcon" onClick={() => play()}>
                                    <svg className="w-7 h-7 ml-[5px]" xmlns="http://www.w3.org/2000/svg"
                                         style={{fill: "#161616"}}
                                         viewBox="0 0 384 512">
                                        <path
                                            d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z"/>
                                    </svg>
                                </div>
                            }
                        </div>
                        <div className={"flex w-1/3 flex-row max-[640px]:hidden items-center justify-end h-full"}>
                            <div className="block relative">
                                <input type='range' id='volume' className={"bg-gray-200 w-68 rounded-xl"} max='100'
                                       onScroll={event => {
                                           event.preventDefault()
                                       }} onWheel={event => {
                                    if (event.deltaY < 0) {
                                        event.currentTarget.value = String(Number(event.currentTarget.value) + 5);
                                    } else {
                                        event.currentTarget.value = String(Number(event.currentTarget.value) - 5);
                                    }
                                }} onChange={(e) => volume(e.target.value)} min='0'/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (player.isActive ? getPlayer() : <></>)

}
export default Player;