import ReactHlsPlayer from "../utils/hls-player";
import React, {Ref, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import Cookies from "js-cookie";

interface PopoutPlayerProps {
    stations: Array<any>
}

const PopoutPlayer: React.FC<PopoutPlayerProps> = ({stations}) => {
    const params = useParams();
    const stationName = params.stream;
    const audioPlayer: Ref<HTMLAudioElement | undefined> = useRef();
    const [playing, setPlaying] = useState(false);
    const [currentVolume, setCurrenVolume] = useState(50);
    const [stationUrl, setStationUrl] = useState("");

    useEffect(() => {
        const m3u_url = stations.find(value => value.short_name === stationName)?.m3u_url;
        if (stationUrl === m3u_url) return;
        setStationUrl(m3u_url)
    }, [stations]);

    const play = () => {
        if(!(audioPlayer && audioPlayer.current)) return;
        audioPlayer.current.currentTime = audioPlayer.current.duration - 1;
        audioPlayer.current.play();
    };

    const defaultVolume = () => {
        if(!(audioPlayer && audioPlayer.current)) return;
        audioPlayer.current.volume = (currentVolume / 150)
    }

    const pause = () => {
        if(!(audioPlayer && audioPlayer.current)) return;
        audioPlayer.current.pause();
    };

    // TODO: Create Volume Slider
    // const volume = (value: string) => {
    //     if(!(audioPlayer && audioPlayer.current)) return;
    //     setCurrenVolume(parseFloat(value))
    //     audioPlayer.current.volume = (parseFloat(value) / 100);
    // }

    const [loopId, setLoopId] = useState<NodeJS.Timer>();
    const [isLoop, setIsLoop] = useState(false);

    useEffect(() => {
        if (isLoop) {
            clearInterval(loopId)
        } else {
            setIsLoop(true)
        }
        updateListen();
        const loopIdLocal = setInterval(() => {
            updateListen()
        }, 60000)
        setLoopId(loopIdLocal)

        function updateListen() {
            if(!(audioPlayer)) return;
            if(!playing) return;
            if (Cookies.get("uXgZvxBcmT") !== null) {
                const myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer " + Cookies.get("uXgZvxBcmT"));
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                const urlencoded = new URLSearchParams();
                urlencoded.append("stationId", stationName ?? "");

                fetch(process.env.REACT_APP_API_URL + "/user/listen", {
                    method: 'POST',
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: 'follow'
                })
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
            }
        }
    }, [playing, stationName])

    return (
        <>
            <ReactHlsPlayer
                style={{display: "none"}}
                src={stationUrl}
                autoPlay={true}
                onPause={() => setPlaying(false)}
                onPlay={() => {
                    setPlaying(true);
                    defaultVolume();
                }}
                playerRef={audioPlayer}
            />

            <div className="w-full h-[299px] bg-[#1e1e1e] flex flex-row overflow-hidden">
                <div className={"absolute w-screen h-screen blur-2xl opacity-50"} style={{
                    backgroundImage: `url(${stations.find(value => value.short_name === stationName)?.current_playing.covers.coverLarge})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}/>
                <div className="w-[200px] ml-[50px] pt-[50px] h-[200px] inline-block relative">
                    <div className="w-full h-full">
                        <img className="rounded-xl"
                             src={stations.find(value => value.short_name === stationName)?.current_playing.covers.coverMedium}
                             alt={""}/>
                    </div>
                </div>
                <div className="pt-[100px] flex flex-col relative">
                    <p className="inline mx-5 text-2xl font-bold">{stations.find(value => value.short_name === stationName)?.current_playing.title}</p>
                    <p className="inline mx-5 text-xl">{stations.find(value => value.short_name === stationName)?.current_playing.artist}</p>
                </div>
                <div className={"absolute bottom-5 right-5"}>
                    {playing ?
                        <div
                            className="h-10 w-10 flex justify-center cursor-pointer items-center rounded-full bg-white fill-[#161616]"
                            id="playerIcon" onClick={() => pause()}>
                            <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 320 512">
                                <path
                                    d="M272 63.1l-32 0c-26.51 0-48 21.49-48 47.1v288c0 26.51 21.49 48 48 48L272 448c26.51 0 48-21.49 48-48v-288C320 85.49 298.5 63.1 272 63.1zM80 63.1l-32 0c-26.51 0-48 21.49-48 48v288C0 426.5 21.49 448 48 448l32 0c26.51 0 48-21.49 48-48v-288C128 85.49 106.5 63.1 80 63.1z"/>
                            </svg>
                        </div>
                        :
                        <div
                            className="h-10 w-10 flex justify-center cursor-pointer ease-in-out items-center rounded-full bg-white fill-[#161616]"
                            id="playerIcon" onClick={() => play()}>
                            <svg className="w-7 h-7 ml-[5px]" xmlns="http://www.w3.org/2000/svg"
                                 style={{fill: "#161616"}}
                                 viewBox="0 0 384 512">
                                <path
                                    d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z"/>
                            </svg>
                        </div>
                    }
                </div>
                <div
                    className={"fixed bottom-0 bg-white h-[3px] z-50 rounded-r-xl transition-all duration-[2500ms] ease-linear animate-[playerFadeIn_500ms]"}
                    style={{width: ((stations.find(value => value.short_name === stationName)?.current_playing.elapsed / stations.find(value => value.short_name === stationName)?.current_playing.duration) * 100) + "%"}}/>
            </div>
        </>
    );
}

export default PopoutPlayer;