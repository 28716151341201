import {createSlice} from '@reduxjs/toolkit';
import {Station} from "../../../component/types";

const initialState: {
    stations: Station[]
} = {
    stations: [],
}

export const stationsSlice = createSlice({
    name: 'stations',
    initialState,
    reducers: {
        updateStations: (state, action) => {
            state.stations = action.payload;
        }
    },
});

export const {updateStations} = stationsSlice.actions;

export default stationsSlice.reducer;