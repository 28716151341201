import React from "react";

interface Props {
}

const PartnerDashboard: React.FC<Props> = () => {
    return(
        <>
            <div className={"top"}>
                
            </div>
        </>
    )
}

export default PartnerDashboard;