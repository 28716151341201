import React from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import BasicPopup from "./BasicPopup";
import {IInfobar} from "../../types";
import {InfoBarComponent} from "../../content/dashboard/admin/InfoBarDashboard";

interface Props {
    children: JSX.Element;
    infoBar: IInfobar;
    updateInfoBar: () => void;
}


const DeleteInfobar: React.FC<Props> = ({children, updateInfoBar, infoBar}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const {t} = useTranslation();
    const close = () => {
        setOpen(false);
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!infoBar) return;
        fetch(process.env.REACT_APP_API_URL + '/info-bar/' + infoBar.id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            },
            body: JSON.stringify(infoBar),
        }).then((value) => {
            if (value.status !== 200) {
                toast.error(t('info-bar.deleteError'));
            } else {
                toast.success(t('info-bar.deleteSuccess'));
                updateInfoBar();
            }
            close();
        })
    }

    return (
        <>
            {React.cloneElement(children, {onClick: () => setOpen(true)})}
            {open ?
                <BasicPopup closeFunction={close} title={"Info Bar Item Löschen"} active={open}>
                    <InfoBarComponent infoBar={infoBar}/>
                    <form onSubmit={onSubmit} className="flex justify-center mt-5">
                        <button className={"bg-red-500 text-white rounded-xl px-5 py-2"} type={"submit"}>Löschen
                        </button>
                    </form>
                </BasicPopup>
                : <></>}
        </>
    )
}

export default DeleteInfobar;