import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ITeam} from "../types";

class Props {
}
const TeamComponent: React.FC<Props> = () => {
    const {t} = useTranslation();
    const [team, setTeam] = useState<ITeam[]>([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/user/team').then(value => {
            value.json().then((teamResponse: ITeam[]) => setTeam(teamResponse))
        })
    }, []);

    return (
        <>
            <div className="top">
                <h1 className="text-3xl font-bold pt-[150px] pb-5 text-center"
                    data-aos="fade-up">{t('team.our_team')}</h1>
                <div
                    className="flex flex-row flex-wrap pt-[50px] mx-auto lg:max-w-screen-lg justify-center pb-10">
                    {team.map((value, index) => {
                        return (
                            <>
                                <div className="flex w-80 my-2" data-aos="fade-up" data-aos-delay={index * 50}>
                                    <img
                                        className="object-cover w-16 h-16 mr-4 rounded-full shadow"
                                        src={value.picture}
                                        alt="Person"
                                    />
                                    <div className="flex flex-col justify-center relative">
                                        <p className="text-lg font-bold transition-all duration-500">{value.username}</p>
                                        <p className="text-sm transition-all duration-500">{value.role.filter(value1 => value1 !== "team").map(value => value && value[0].toUpperCase() + value.slice(1)).join(", ")}</p>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default TeamComponent;