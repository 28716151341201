import React from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import BasicPopup from "./BasicPopup";
import {InfoBarComponent} from "../../content/dashboard/admin/InfoBarDashboard";
import {IInfobar} from "../../types";

interface Props {
    children: JSX.Element;
    updateInfoBar: () => void;
}


const CreateInfobar: React.FC<Props> = ({children, updateInfoBar}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [infobar, setInfobar] = React.useState<IInfobar>({
        title: "",
        description: "",
        color: "",
        textColor: "",
        endDate: 0,
        startDate: 0,
    });
    const {t} = useTranslation();
    const close = () => {
        setOpen(false);
    }

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        if(e.target.name === "endDate" || e.target.name === "startDate") {
            console.log(e.target.value)
            setInfobar({...infobar, [e.target.name]: Date.parse(e.target.value)});
            return;
        }
        setInfobar({...infobar, [e.target.name]: e.target.value});
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!infobar) return;
        fetch(process.env.REACT_APP_API_URL + '/info-bar/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            },
            body: JSON.stringify({
                ...infobar,
                endDate: infobar.endDate,
                startDate: infobar.startDate
            }),
        }).then((value) => {
            if (value.status !== 201) {
                toast.error(t('info-bar.createError'));
            } else {
                setInfobar({
                    title: "",
                    description: "",
                    color: "",
                    textColor: "",
                    endDate: 0,
                    startDate: 0,
                })
                toast.success(t('info-bar.createSuccess'));
                updateInfoBar();
            }
            close();
        })
    }

    return (
        <>
            {React.cloneElement(children, {onClick: () => setOpen(true)})}
            {open ?
                <BasicPopup closeFunction={close} title={"Info-Bar Item Erstellen"} active={open}>
                    <form onSubmit={onSubmit}>
                        <div className={"flex md:flex-row flex-col w-full relative"}>
                            <div className={"flex flex-col w-full m-2"}>
                                <label className="text-lg py-1">{t("info-bar.title")}</label>
                                <input type="text" defaultValue={infobar.title}
                                       className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler}
                                       required={true}
                                       name={"title"} placeholder={t("info-bar.placeholder.title").toString()}/>
                            </div>
                        </div>
                        <div className={"flex md:flex-row flex-col relative"}>
                            <div className={"flex flex-col w-full m-2"}>
                                <label className="text-lg py-1">{t("info-bar.description")}</label>
                                <textarea defaultValue={infobar.description} name={"description"}
                                          className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler}
                                          placeholder={t("info-bar.placeholder.description").toString()}/>
                            </div>
                        </div>
                        <div className={"flex md:flex-row flex-col relative"}>
                            <div className={"flex flex-col md:w-1/2 w-full m-2"}>
                                <label className="text-lg py-1">{t("info-bar.startDate")}</label>
                                <input type="datetime-local" defaultValue={infobar.startDate ?? 0}
                                       className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler}
                                       required={true}
                                       name="startDate" placeholder={t("info-bar.placeholder.startDate").toString()}/>
                            </div>
                            <div className={"flex flex-col md:w-1/2 w-full m-2"}>
                                <label className="text-lg py-1">{t("info-bar.endDate")}</label>
                                <input type="datetime-local" defaultValue={infobar.endDate ?? 0}
                                       className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler}
                                       required={true}
                                       name="endDate" placeholder={t("info-bar.placeholder.endDate").toString()}/>
                            </div>
                        </div>
                        <div className={"flex md:flex-row flex-col relative"}>
                            <div className={"flex flex-col md:w-1/2 w-full m-2"}>
                                <label className="text-lg py-1">{t("info-bar.backgroundColor")}</label>
                                <input type="color" defaultValue={infobar.startDate ?? 0}
                                       className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler}
                                       required={true}
                                       name="color" placeholder={t("info-bar.placeholder.startDate").toString()}/>
                            </div>
                            <div className={"flex flex-col md:w-1/2 w-full m-2"}>
                                <label className="text-lg py-1">{t("info-bar.textColor")}</label>
                                <input type="color" defaultValue={infobar.endDate ?? 0}
                                       className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler}
                                       required={true}
                                       name="textColor" placeholder={t("info-bar.placeholder.endDate").toString()}/>
                            </div>
                        </div>
                        <button className="p-1 rounded-lg bg-secondary w-full ml-2 mt-5"
                                type={"submit"}>{t("info-bar.save")}</button>
                    </form>
                    <h2 className="text-center text-xl font-bold my-5">Preview</h2>
                    <InfoBarComponent infoBar={infobar}/>
                </BasicPopup>
                : <></>}
        </>
    )
}

export default CreateInfobar;