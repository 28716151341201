import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import toast from 'react-hot-toast';
import BasicPopup from "./BasicPopup";
import Cookies from "js-cookie";

interface IPartner {
    name: string;
    description: string;
    thumbnail: any;
    logo: any;
    short_description: string;
}


interface Props {
    children: JSX.Element;
    updatePartner: () => void;
}

const CreatePartner: React.FC<Props> = ({children, updatePartner}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [partner, setPartner] = useState<IPartner>({
        name: "",
        short_description: "",
        description: "",
        logo: null,
        thumbnail: null,
    });

    const changeHandler = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        if (target.type === "file") {
            if (target.files && target.files.length !== 0) {
                console.log(target.files[0])
                setPartner({...partner, [target.name]: target.files[0]});
            }
        } else {
            setPartner({...partner, [target.name]: target.value});
        }
    }

    const changeHandlerTextArea = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const target = event.target as HTMLTextAreaElement;
        setPartner({...partner, [target.name]: target.value});
    }

    const {t} = useTranslation();
    const close = () => {
        setOpen(false);
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const myHeaders = new Headers();
        myHeaders.set("Authorization", `Bearer ${Cookies.get('uXgZvxBcmT')}`)
        const body = new FormData();
        body.append("name", partner.name);
        body.append("short_description", partner.short_description);
        body.append("description", partner.description);
        body.append("logo", partner.logo);
        body.append("thumbnail", partner.thumbnail);
        fetch(process.env.REACT_APP_API_URL + '/partner/create', {
            method: "POST",
            headers: myHeaders,
            body: body
        }).then(value => {
            close();
            value.json().then(value1 => {
                if (value.status !== 201) {
                    toast.error(t(value1.message));
                } else {
                    toast.success(t(value1.message));
                }
                setPartner({
                    name: "",
                    short_description: "",
                    description: "",
                    logo: null,
                    thumbnail: null,
                });
                updatePartner();
            })
        })

    }

    return (
        <>
            {React.cloneElement(children, {onClick: () => setOpen(true)})}
            {open ?
                <BasicPopup closeFunction={close} title={"Partner Erstellen"} active={open}>
                    <form onSubmit={onSubmit}>
                        <div className={"flex md:flex-row flex-col w-full relative"}>
                            <div className={"flex flex-col w-full m-2"}>
                                <label className="text-lg py-1">{t("partner.name")}</label>
                                <input type="text" defaultValue={partner.name}
                                       className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler}
                                       required={true}
                                       name={"name"} placeholder={t("partner.placeholder.name").toString()}/>
                            </div>
                        </div>
                        <div className={"flex md:flex-row flex-col relative"}>
                            <div className={"flex flex-col w-full m-2"}>
                                <label className="text-lg py-1">{t("partner.short_description")}</label>
                                <input type={"text"} defaultValue={partner.short_description} name={"short_description"}
                                       className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler}
                                       placeholder={t("partner.placeholder.short_description").toString()}/>
                            </div>
                        </div>
                        <div className={"flex md:flex-row flex-col relative"}>
                            <div className={"flex flex-col w-full m-2"}>
                                <label className="text-lg py-1">{t("partner.description")}</label>
                                <textarea defaultValue={partner.description}
                                          className="p-1 w-full rounded-lg bg-secondary"
                                          onChange={changeHandlerTextArea}
                                          required={true}
                                          name="description"
                                          placeholder={t("partner.placeholder.description").toString()}/>
                            </div>
                        </div>
                        <div className={"flex md:flex-row flex-col relative"}>
                            <div className={"md:w-1/2 m-2 w-full"}>
                                <div className={"flex flex-col w-full"}>
                                    <label className="text-lg py-1">{t("partner.logo")}</label>
                                    <div className={"flex flex-row max-w-[300px] rounded-lg bg-secondary items-center justify-center"}>
                                        <input type={"file"} accept={"image/png, image/jpeg"}
                                               onChange={changeHandler}
                                               required={true}
                                               name="logo"
                                               id={"logo-input"}
                                               hidden={true}
                                               placeholder={t("partner.placeholder.logo").toString()}/>
                                        <label
                                            htmlFor="logo-input"
                                            className="block text-sm mr-4 py-2 px-4 text-white border-0 truncate cursor-pointer"
                                        >
                                            Wähle eine Datei
                                        </label>
                                        <label className="truncate">{partner.logo ? partner.logo.name : ""}</label>
                                    </div>
                                </div>
                            </div>
                            <div className={"md:w-1/2 pl-2 m-2 w-full"}>
                                <div className={"flex flex-col w-full"}>
                                    <label className="text-lg py-1">{t("partner.thumbnail")}</label>
                                    <div
                                        className={"flex flex-row max-w-[300px] rounded-lg bg-secondary items-center justify-center"}>
                                        <input type={"file"} accept={"image/png, image/jpeg"}
                                               onChange={changeHandler}
                                               required={true}
                                               name="thumbnail"
                                               id={"thumbnail-input"}
                                               hidden={true}
                                               placeholder={t("partner.placeholder.thumbnail").toString()}/>
                                        <label
                                            htmlFor="thumbnail-input"
                                            className="block text-sm mr-4 py-2 px-4 text-white border-0 truncate cursor-pointer"
                                        >
                                            Wähle eine Datei
                                        </label>
                                        <label className="truncate">{partner.thumbnail ? partner.thumbnail.name : ""}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="p-1 rounded-lg bg-secondary w-full ml-2 mt-5"
                                type={"submit"}>{t("partner.create")}</button>
                    </form>
                </BasicPopup>
                : <></>}
        </>
    )
}

export default CreatePartner;
