import React, {useEffect} from "react";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";

interface Props {
}

const EmailComponent: React.FC<Props> = () => {
    const navigate = useNavigate();
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/user/activate/${window.location.search.split("=")[1]}`, {
            method: "POST",
        }).then(value => {
            if(value.status === 200) {
                toast.success("E-Mail wurde erfolgreich bestätigt")
            } else {
                toast.error("Die E-Mail konnte nicht Verifiziert werden. Versuche es erneut...")
            }
            navigate("/");
        })
    }, [])

    return (<></>)
}

export default EmailComponent;