import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
}

const Imprint: React.FC<Props> = () => {
    const {t} = useTranslation();
    return (
        <>
            <div className="top">
                <div className="lg:w-1/2 w-3/4 mx-auto text-center">
                    <h1 className="text-4xl font-bold pt-[150px] pb-5"
                        data-aos="fade-up">Impressum</h1>
                    <p className={"text-lg font-light"} data-aos="fade-up"
                       data-aos-delay={50}>{t('imprint.lastupdate')}</p>
                </div>
                <div className="lg:w-1/2 w-3/4 mx-auto">
                    <div className="pt-10">
                        <div className="flex lg:flex-row flex-col pb-10">
                            <div className="flex flex-col w-1/2">
                                <h3 className="text-2xl font-bold pb-5">Adresse</h3>
                                <div className={"pl-1"}>
                                    <p>Niklas Holy</p>
                                    <p>Schachenstrasse 17</p>
                                    <p>9430 St.Margrethen</p>
                                    <p>Schweiz</p>
                                </div>
                            </div>
                            <div className="w-1/2">
                                <h3 className="text-2xl font-bold pb-5">Kontakt-Daten</h3>
                                <div className={"pl-1"}>
                                    <p className="cursor-pointer hover:text-indigo-300 transition-all duration-500"
                                       onClick={() => window.open('mailto://niklas@breathfm.de')}>niklas@breathfm.de</p>
                                </div>
                            </div>
                        </div>
                        <div className={"pb-10"}>
                            <h3 className="text-2xl pb-5 font-bold uppercase">Urheberrechte</h3>
                            <p className="text-lg pl-1">
                                Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien
                                auf
                                dieser Website, gehören ausschliesslich Niklas Holy oder den speziell genannten
                                Rechteinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung
                                des
                                Urheberrechtsträgers im Voraus einzuholen.
                            </p>
                        </div>
                        <div className={"pb-10"}>
                            <h3 className="text-2xl pb-5 font-bold uppercase">Haftungsausschluss</h3>
                            <p className="text-lg pl-1">
                                Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche
                                aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch
                                Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden
                                ausgeschlossen.<br/>
                                Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der
                                Seiten oder das gesamte Angebot ohne besondere Ankündigung zu verändern, zu ergänzen, zu
                                löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
                            </p>
                        </div>
                        <div className={"pb-20"}>
                            <h3 className="text-2xl pb-5 font-bold uppercase">Haftungsausschluss für Links</h3>
                            <p className="text-lg pl-1">
                                Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs.
                                Es wird jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung
                                solcher Webseiten erfolgen auf eigene Gefahr des jeweiligen Nutzers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Imprint;