const PageNotFound = () => {
    return (
        <>
            <div className="top">
                <section className="flex min-h-[85vh] items-center p-16">
                    <div className="container h-full flex flex-col items-center justify-center px-5 mx-auto my-8">
                        <div className="max-w-md text-center">
                            <h2 className="mb-8 font-extrabold text-9xl">
                                <span className="sr-only">Error</span>404
                            </h2>
                            <p className="text-2xl block font-semibold md:text-3xl">Sorry, wir haben keine Seite gefunden.</p>
                            <a rel="noreferrer" href="/"
                               className="px-8 block mt-5 w-72 mx-auto py-3 transition-all hover:-translate-y-0.5 font-semibold rounded-lg bg-primary">Zurück zur
                                Hauptseite</a>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default PageNotFound;