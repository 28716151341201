import React, {useEffect, useState} from 'react';
import HomePage from "./content/homepage";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Bots from "./content/bots";
import History from "./content/History";
import Imprint from "./content/imprint";
import Datenschutz from "./content/datenschutz";
import DiscordCallback from "./utils/DiscordCallback";
import Dashboard from "./content/dashboard/home";
import i18n from "i18next";
import TeamComponent from "./content/Team";
import RegisterComponent from "./auth/register";
import LoginComponent from "./auth/login";
import EmailComponent from "./content/email";
import AdminDashboard from "./content/dashboard/admin/default";
import PartnerDashboard from "./content/dashboard/partner/PartnerDashboard";
import PartnerPage from "../pages/PartnerPage";
import SettingsComponent from "./content/dashboard/settings";
import ForgetPasswordComponent from "./auth/forgetPassword";
import NewPasswordComponent from "./auth/newPasswordSet";
import TermsOfService from "./content/termsAtService";
import PopoutPlayer from "./player/PopoutPlayer";
import {Station, User} from "./types";
import {Toaster} from "react-hot-toast";
import Cookies from "js-cookie";
import {DefaultLayout} from "./layout/DefaultLayout";
import {useDispatch, useSelector} from "react-redux";
import {updateStations} from "../features/slice/stations/stationsSlice";
import {updateUser} from "../features/slice/user/userSlice";
import TeamspeakBotSettings from "./content/dashboard/teamspeakBotSettings";
import HistoryDashboard from "./content/dashboard/admin/HistoryDashboard";
import InfoBarDashboard from "./content/dashboard/admin/InfoBarDashboard";
import BotDashboard from "./content/dashboard/admin/BotDashboard";
import UserAdminDashboard from "./content/dashboard/admin/UserAdminDashboard";
import AdminLayout from "./layout/AdminLayout";
import UserLayout from "./layout/UserLayout";
import PageNotFound from "./PageNotFound";
import PartnerAdminDashboard from "./content/dashboard/admin/PartnerAdminDashboard";
import AccountDeletingComponent from "./content/AccountDeleting";
import ProfilePage from "../pages/ProfilePage";

interface Props {
}

const App: React.FC<Props> = () => {
    const {stations} = useSelector((state: any) => state.stations);
    const dispatch = useDispatch();

    const [isLoop, setIsLoop] = useState(true);
    const [loopId, setLoopId] = useState<NodeJS.Timer>();
    const [isWinter, setIsWinter] = useState(false);

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + Cookies.get('uXgZvxBcmT'));
        fetch(process.env.REACT_APP_API_URL + '/user/validation', {
            headers: myHeaders,
        }).then(value => {
            if (value.status !== 200) {
                dispatch(updateUser(null));
            } else {
                value.json().then((userResponse: User) => dispatch(updateUser(userResponse)))
            }
        }).catch(() => {

        })
    }, [Cookies.get('uXgZvxBcmT')])

    useEffect(() => {
        try {
            // @ts-ignore
            document.getElementsByTagName("html").item(0).lang = i18n.language
        } catch (error) {
        }
        if (isLoop) {
            clearInterval(loopId)
        } else {
            setIsLoop(true)
        }
        updateStationsApi()
        const loopIdLocal = setInterval(() => {
            updateStationsApi()
        }, 2500)
        setLoopId(loopIdLocal)
        checkIsWinter()

        function checkIsWinter() {
            const date = new Date();
            if ((date.getMonth() === 10 && (date.getDate() === 27 || date.getDate() === 28 || date.getDate() === 29 || date.getDate() === 30)) ||
                date.getMonth() === 11) {
                setIsWinter(process.env.NODE_ENV === 'production')
            }
        }

        function updateStationsApi() {
            fetch(process.env.REACT_APP_API_URL + "/station/nowplaying")
                .then((value) => {
                    value.json().then((value1: Station[]) => {
                        dispatch(updateStations(value1))
                    })
                })
        }

        // eslint-disable-next-line
    }, [isLoop])

    return (
        <>
            <Toaster position={"bottom-center"} toastOptions={{
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }}/>
            <Router>
                <Routes>
                    <Route path="/player/:stream" element={<PopoutPlayer stations={stations}/>}/>
                    <Route path="*" element={<DefaultLayout isWinter={isWinter}/>}>
                        <Route index
                               element={<HomePage isWinter={isWinter}/>}/>
                        <Route path='bots'
                               element={<Bots/>}/>
                        <Route path="discord-callback"
                               element={<DiscordCallback/>}/>
                        <Route path='partner'
                               element={<PartnerPage/>}/>
                        <Route path='signup'
                               element={<RegisterComponent/>}/>
                        <Route path='forget'
                               element={<ForgetPasswordComponent/>}/>
                        <Route path='resetPassword'
                               element={<NewPasswordComponent/>}/>
                        <Route path='email'
                               element={<EmailComponent/>}/>
                        <Route path='profile/:username'
                               element={<ProfilePage/>}/>
                        <Route path='deletingVerify'
                               element={<AccountDeletingComponent/>}/>
                        <Route path='signin'
                               element={<LoginComponent/>}/>
                        <Route path="dashboard" element={<UserLayout/>}>
                            <Route index
                                   element={<Dashboard/>}/>
                            <Route path=':id/bot/teamspeak'
                                   element={<TeamspeakBotSettings/>}/>
                            <Route path='settings'
                                   element={<SettingsComponent/>}/>
                            <Route path={"partner"}>
                                <Route index
                                       element={<PartnerDashboard/>}/>
                                <Route path={':id'}
                                       element={<PartnerDashboard/>}/>
                            </Route>
                        </Route>
                        <Route path="admin" element={<AdminLayout/>}>
                            <Route index
                                   element={<AdminDashboard/>}/>
                            <Route path='history'
                                   element={<HistoryDashboard/>}/>
                            <Route path='infobar'
                                   element={<InfoBarDashboard/>}/>
                            <Route path='bot'
                                   element={<BotDashboard/>}/>
                            <Route path='user'
                                   element={<UserAdminDashboard/>}/>
                            <Route path='partner'
                                   element={<PartnerAdminDashboard/>}/>
                        </Route>
                        <Route path="discord-callback"
                               element={<DiscordCallback/>}/>
                        <Route path='history'
                               element={<History/>}/>
                        {/* <Route path="atmosplus"
                               element={<AtmosPlus/>}/> */}
                        <Route path="imprint"
                               element={<Imprint/>}/>
                        <Route path="privacy"
                               element={<Datenschutz/>}/>
                        <Route path="agb"
                               element={<TermsOfService/>}/>
                        <Route path="team"
                               element={<TeamComponent/>}/>
                        <Route path="*" element={<PageNotFound/>}/>
                    </Route>
                </Routes>
            </Router>
        </>
    )
}

export default App;