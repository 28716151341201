import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import discord_1 from "./../../assets/img/bots/discord/1.png";
import discord_2 from "./../../assets/img/bots/discord/2.png";
import discord_3 from "./../../assets/img/bots/discord/3.png";
import discord_4 from "./../../assets/img/bots/discord/4.png";
import discord_5 from "./../../assets/img/bots/discord/5.png";
import teamspeakPicture from "./../../assets/img/teamspeak-bot.png";
import {Link} from "react-router-dom";

interface Props {
}

const StationCard: React.FC<Props> = () => {
    const {t} = useTranslation();
    const [selectPicture, setSelectPicture] = useState<number>(0);
    const pictues = [
        discord_1,
        discord_2,
        discord_3,
        discord_4,
        discord_5,
    ]

    return (
        <>
            <div className='top'>
                <h1 className="text-3xl font-bold pt-[150px] pb-5 text-center"
                    data-aos="fade-up">{t('bots.musicbots')}</h1>

                <div className={"max-w-[1920px] mx-auto pt-5"}>
                    <div className={"flex lg:flex-row flex-col py-5"}>
                        <div className="lg:w-1/2 w-full px-5">
                            <div className={"flex flex-col"}>
                                <img src={pictues[selectPicture]} className="w-full rounded-xl transition-all duration-500" data-aos="fade-up" data-aos-delay={"50"} alt={"Showing Picture"}/>
                                <div className="flex flex-row flex-nowrap overflow-x-auto">
                                    {pictues.map((value, index) => {
                                        return (
                                            <>
                                                <img src={value} className={"lg:w-44 w-32 rounded-xl m-2.5 duration-500 translate-all" + (index === selectPicture ? " border border-indigo-500" : "")} onClick={() => setSelectPicture(index)} alt={"Picture-" + index}/>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/2 w-full px-5">
                            <h1 className={"text-2xl font-bold"} data-aos="fade-up" data-aos-delay={"150"}>Discord Bot</h1>
                            <p className="text-lg py-5" data-aos="fade-up" data-aos-delay={"200"}>
                                Wir bieten dir einen Bot, der die ganze Zeit in deinem Talk sein kann.<br/>
                                Du kannst entscheiden, ob er im Channel bleiben soll, oder nachdem die letzte Person
                                gegangen ist,<br/>
                                ebenfalls die Verbindung trennen sollte.<br/>
                                Jeder kann unseren Discord-Bot kostenlos benutzen. Du siehst unten eine Commandliste
                                sowie einen<br/>
                                Einladungslink. Bei Fragen oder Problemen melde dich doch gerne auf unserem
                                Discord-Server.<br/>
                            </p>

                            <h2 className="text-xl font-medium" data-aos="fade-up" data-aos-delay={"200"}>Alle Commands:</h2>
                            <div className={"flex flex-row flex-wrap pt-5"} data-aos="fade-up" data-aos-delay={"200"}>
                                <p className="bg-primary px-2 py-1 rounded-lg m-2.5">/play</p>
                                <p className="bg-primary px-2 py-1 rounded-lg m-2.5">/song</p>
                                <p className="bg-primary px-2 py-1 rounded-lg m-2.5">/lang</p>
                                <p className="bg-primary px-2 py-1 rounded-lg m-2.5">/leaderboard</p>
                                <p className="bg-primary px-2 py-1 rounded-lg m-2.5">/rank</p>
                                <p className="bg-primary px-2 py-1 rounded-lg m-2.5">/info</p>
                                <p className="bg-primary px-2 py-1 rounded-lg m-2.5">/settings alwayscon</p>
                                <p className="bg-primary px-2 py-1 rounded-lg m-2.5">/settings defaultchannel</p>
                                <p className="bg-primary px-2 py-1 rounded-lg m-2.5">/settings showpicture</p>
                            </div>
                        </div>
                    </div>
                    <div className={"flex lg:flex-row flex-col py-10"}>
                        <div className="lg:w-1/2 w-full px-5">
                            <h1 className={"text-2xl font-bold"} data-aos="fade-up">Teamspeak Bot</h1>
                            <p className="text-lg py-5" data-aos="fade-up" data-aos-delay={"50"}>
                                Deinem Teamspeak fehlt das gewisse Etwas?<br/>
                                Wir bieten dir komplett unverbindlich und ohne Partnerschaft unseren Teamspeak Bot an.<br/>
                                Der Bot enthält keine versteckten Kosten und sollte 24/7 online im Channel bleiben, auch wenn niemand anderes da ist.<br/>
                            </p>
                            <h2 className="text-xl font-medium">Die Voraussetzungen:</h2>
                            <ul className="list-disc ml-10">
                                <li className="text-lg text-indigo-200 my-2"><Link className={"hover:text-indigo-300 duration-400 transition-all"} to={"/signup"}>Einen Account hier auf der Webseite erstellen</Link></li>
                                <li className="text-lg my-2">Per E-Mail verifizieren</li>
                                <li className="text-lg my-2">Bot erstellen</li>
                            </ul>
                        </div>
                        <div className="lg:w-1/2 w-full px-5">
                            <div className={"flex flex-col"}>
                                <img src={teamspeakPicture} className="w-full rounded-xl" data-aos="fade-up" data-aos-delay={"50"} alt={"Teamspeak Picture"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StationCard;