import React, {useState} from "react";
import StationCard from "./station-card";
import "keen-slider/keen-slider.min.css"
import {useTranslation} from "react-i18next";
import Weather from "../utils/Weaather";
import PodcastComponent from "../utils/Podcast";
import LastSongs from "../utils/Popups/LastSongs";
import LiveTimetable from "../utils/Popups/LiveTimetable";
import {useSelector} from "react-redux";
import {Station} from "../types";

interface Props {
    isWinter: boolean
}

const HomePage: React.FC<Props> = ({
                                       isWinter
                                   }) => {

    const {t} = useTranslation();
    const {stations} = useSelector((state: any) => state.stations);
    const [lastSongs, setLastSongs] = useState([])
    const [liveTableModal, setLiveTableModal] = useState("");

    const closeLastSongs = () => {
        setLastSongs([]);
    }

    const closeLiveTimeTable = () => {
        setLiveTableModal("");
    }

    return (
        <>
            <LastSongs close={closeLastSongs} isActive={lastSongs.length !== 0} lastSongs={lastSongs}/>
            <LiveTimetable isActive={liveTableModal !== "" && liveTableModal === "breathfm"}
                           close={closeLiveTimeTable}/>
            <div className="image"></div>
            <div className="top">
                <div className="text-center pt-[150px]">
                    <h1 className="text-[50px] font-bold m-0" data-aos="fade-up">BreathFM</h1>
                    <p className="m-0 p-0 text-[21px] font-light" data-aos="fade-up" data-aos-delay="50">{t('homepage.title.description')}</p>
                </div>
                <div className="flex justify-center items-center">
                    <div className="w-80 mt-24 relative z-0" data-aos="fade-up" data-aos-delay="100">
                        <StationCard setLastSongs={setLastSongs} playlist={false}
                                     station={stations[0]} isWinter={isWinter}/>
                    </div>
                </div>
                {isWinter ?
                    <main>
                        <span></span>
                        <div></div>
                        <div></div>
                    </main>
                    : <></>}
                <Weather/>
            </div>
            <div className="waves">

            </div>
            <section className="mischpultImage w-full overflow-hidden">
                <h1 className="pt-[175px] mt-0 mb-[5px] text-center text-[45px] font-bold">{t('homepage.our-channel')}</h1>
                <p className="text-center mt-0 pt-0 mb-[50px] text-[25px] font-[100]">{t('homepage.our-channel.description')}</p>
                <button className="block mx-auto bg-[#5E5E5F87] py-[10px] px-[5px] mb-[50px] rounded-xl text-white transition-all duration-500 text-[16px] hover:bg-[#51505087]"
                        onClick={() => setLiveTableModal("breathfm")}>{t('homepage.livestreamer')}</button>
                <div className="flex mb-[75px] pb-5 flex-nowrap flex-row overflow-x-auto" style={{justifyContent: "safe center", alignItems: "safe center"}}>
                    {stations.map((value: Station, index: number) => {
                        return (
                            <>
                                <div key={index} className="text-center w-96 mx-auto flex-shrink-0 px-5" style={{margin: "auto"}}>
                                    <p className="text-center text-[25px] font-[600]">{value.name}</p>
                                    <StationCard setLastSongs={setLastSongs}
                                                 station={value} isWinter={isWinter}/>
                                </div>
                            </>
                        )
                    })}
                </div>
                <div className="max-[1174px]:w-[90%] max-[1174px]:pb-[100px] max-[1464px]:w-[50%] mx-auto max-[1464px]:pb-[100px] min-[1464px]:w-[40%] min-[1464px]:pb-[100px]">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 512 512"
                         className="max-[666px]:hidden min-[666px]:w-[40px] min-[666px]:inline-block min-[666px]:m-[13px]"
                         fill="white">
                        <path
                            d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
                    </svg>
                    <p className="inline-block p-0 m-0">{t('homepage.all-channel.description.first')}<br/>{t('homepage.all-channel.description.second')}<br/>{t('homepage.all-channel.description.third')}
                    </p>
                </div>
            </section>
            <div className="waves rotate">

            </div>
            <PodcastComponent/>
        </>
    )
}

export default HomePage;