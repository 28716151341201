import React, {useEffect, useState} from "react";
import BasicPopup from "./BasicPopup";
import {useTranslation} from "react-i18next";
import {ITeamspeakBotSettings} from "../../types";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

interface Props {
    children: JSX.Element;
    teamspeakBot: ITeamspeakBotSettings | null;
    updateTeamspeakBot: () => void;
}

const EditTeamspeakBotComponent: React.FC<Props> = ({children, teamspeakBot, updateTeamspeakBot}) => {
    const {t} = useTranslation();
    const [open, setOpen] = React.useState<boolean>(false);
    const [bot, setBot] = useState<ITeamspeakBotSettings | null>(teamspeakBot);
    const close = () => setOpen(false);

    const [streamList, setStreamList] = React.useState<any[]>([]);

    useEffect(() => {
        setBot(teamspeakBot)
    }, [teamspeakBot]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/musicbot/streamURL/list', {
            headers: {
                "Authorization": "Bearer " + Cookies.get('uXgZvxBcmT'),
            },
        }).then(async value => {
            if (value.status !== 200) return;
            value.json().then(value1 => {
                setStreamList(value1)
            })
        })
    }, []);

    if(bot === null) return (<></>)

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setBot({...bot, [e.target.name]: e.target.value})
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!teamspeakBot) return;
        fetch(process.env.REACT_APP_API_URL + '/musicbot/' + teamspeakBot.id + "/info", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            },
            body: JSON.stringify(bot),
        }).then(async (value) => {
            if (value.status === 200) {
                toast.success(t((await value.json()).message));

            } else {
                toast.error(t((await value.json()).message));
            }
            updateTeamspeakBot();
            close();
        })
    }

    return (
        <>
            {React.cloneElement(children, {onClick: () => setOpen(true)})}
            {open ?
                <BasicPopup closeFunction={close} title={t("teamspeakbots.edit")} active={open}>
                    <div className="flex flex-row flex-wrap w-full my-2">
                        <form className="w-full mr-3" onSubmit={onSubmit}>
                            <div className={"flex md:flex-row flex-col relative"}>
                                <div className={"flex flex-col md:w-1/2 w-full m-2"}>
                                    <label className="text-lg py-1">{t("teamspeakbots.name")}</label>
                                    <input defaultValue={bot.name} type="text" name="name" className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler}
                                           placeholder={t("teamspeakbots.name").toString()}/>
                                </div>
                                <div className={"flex flex-col md:w-1/2 w-full m-2 relative"}>
                                    <label className="text-lg py-1">{t('teamspeakbots.channel')}</label>
                                    <select name="channelId" defaultValue={bot?.channelId ?? ""} onChange={changeHandler} className={"bg-secondary w-full rounded-lg p-1"} disabled={bot?.botId === null} required={true}>
                                        <option defaultChecked={true} disabled={bot?.channelId !== null}>----
                                        </option>
                                        {bot?.server?.Channels.map(value => {
                                            return (
                                                <>
                                                    <option value={value.Id}>{value.Name}</option>
                                                </>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className={"flex md:flex-row flex-col w-full relative"}>
                                <div className={"flex flex-col md:w-1/2 w-full m-2"}>
                                    <label className="text-lg py-1">{t("teamspeakbots.serverAddress")}</label>
                                    <input type="text" defaultValue={bot.serverAddress} className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler} required={true}
                                           name={"serverAddress"} placeholder={t("teamspeakbots.serverAddress").toString()}/>
                                </div>
                                <div className={"flex flex-col md:w-1/2 w-full m-2"}>
                                    <label className="text-lg py-1">{t("teamspeakbots.serverPort")}</label>
                                    <input type="number" defaultValue={bot.serverPort} className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler} required={true}
                                           name="serverPort" placeholder={t("teamspeakbots.serverPort").toString()}/>
                                </div>
                            </div>
                            <div className={"w-full relative"}>
                                <div className={"w-full m-2 flex flex-col"}>
                                    <label className="text-lg py-1">{t('teamspeakbots.stream')}</label>
                                    <select name="streamUrl" className={"bg-secondary rounded-lg p-1 w-full"} onChange={changeHandler} required={true}>
                                        {streamList.map(value => {
                                            return (
                                                <>
                                                    <option value={value.streamUrl} selected={bot?.streamUrl === value.streamUrl}>{value.name}</option>
                                                </>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <button className="p-1 rounded-lg bg-secondary w-full ml-2 mt-5" type={"submit"}>{t("teamspeakbots.save")}</button>
                        </form>
                    </div>
                </BasicPopup>
                : <></>}
        </>
    )
}

export default EditTeamspeakBotComponent;
