import React, {useEffect, useState} from "react";
import {IPartner} from "../../../types";
import CreatePartner from "../../../utils/Popups/CreatePartner";
import DeletePartner from "../../../utils/Popups/DeletePartner";
import PartnerItemComponent from "../../../partner/PartnerItemComponent";

const PartnerAdminDashboard = () => {
    const [partner, setPartner] = useState<IPartner[]>([]);
    useEffect(() => {
        updatePartner();
    }, [])

    function updatePartner() {
        fetch(`${process.env.REACT_APP_API_URL}/partner/all`).then(value => {
            value.json().then(value1 => setPartner(value1));
        })
    }

    return (
        <>
            <div className={"top"}>
                <h1 className="text-3xl font-bold pt-[150px] text-center pb-5">Partner</h1>
                <div className="flex justify-center">
                    <CreatePartner updatePartner={updatePartner}>
                        <button className={"bg-primary mx-auto text-white rounded-xl px-5 py-2"}>Neuen Eintrag
                            hinzufügen
                        </button>
                    </CreatePartner>
                </div>
                <div className={"flex flex-col flex-wrap max-w-[1920px] pt-5 px-10 mx-auto"}>
                    {partner.map((value, index) => {
                            return (
                                <PartnerItemComponent partner={value} index={index}>
                                    <div className="absolute right-0 bottom-0 m-2 flex flex-row">
                                        <DeletePartner partner={value} updatePartner={updatePartner}>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14"
                                                 className={"fill-red-500 hover:fill-red-600 cursor-pointer m-2 transition-all duration-500"}
                                                 viewBox="0 0 448 512">
                                                <path
                                                    d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                                            </svg>
                                        </DeletePartner>
                                    </div>
                                </PartnerItemComponent>
                            )
                        }
                    )}
                </div>
            </div>
        </>
    )
}

export default PartnerAdminDashboard;