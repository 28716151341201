import React, {useEffect} from "react";
import {IHistory} from "../../../types";
import CreateHistory from "../../../utils/Popups/CreateHistory";
import i18n from "i18next";
import EditHistory from "../../../utils/Popups/EditHistory";
import DeleteHistory from "../../../utils/Popups/DeleteHistory";

const HistoryDashboard = () => {

    const [history, setHistory] = React.useState<IHistory[]>([]);

    useEffect(() => {
        updateHistory();
    }, []);

    const updateHistory = () => {
        fetch(`${process.env.REACT_APP_API_URL}/history/all`).then(value => {
            value.json().then(value1 => setHistory(value1));
        });
    }

    return (
        <>
            <div className="min-h-[85vh]">
                <h1 className="text-3xl font-bold pt-[150px] pb-5 text-center">History bearbeiten</h1>
                <div className="flex justify-center">
                    <CreateHistory updateHistory={updateHistory}>
                        <button className={"bg-primary mx-auto text-white rounded-xl px-5 py-2"}>Neuen Eintrag
                            hinzufügen
                        </button>
                    </CreateHistory>
                </div>
                <div className={"lg:w-[50vw] mx-auto w-[90vw] pb-28 pt-5"}>
                    <div
                        className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-primary before:to-transparent">
                        {history.map((value, index) => {
                            return (
                                <>
                                    <HistoryComponent index={index + 1} title={value.title}
                                                      updateHistory={updateHistory}
                                                      description={value.description} date={new Date(value.date)}
                                                      id={value.id}/>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

interface HistoryComponentProps {
    id: number;
    index: number;
    title: string;
    description: string;
    date: Date;
    updateHistory: () => void;
}

const HistoryComponent: React.FC<HistoryComponentProps> = ({index, title, description, date, updateHistory, id}) => {
    const formatter = new Intl.DateTimeFormat(i18n.language, {month: '2-digit', year: 'numeric', day: '2-digit'})

    return (
        <>
            <div
                className="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active"
                data-aos="fade-up" data-aos-delay={index * 50}>
                <div
                    className="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-primary group-[.is-active]:bg-emerald-500 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="12" height="10">
                        <path fillRule="nonzero"
                              d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z"/>
                    </svg>
                </div>
                <div
                    className="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-primary p-4 rounded-lg shadow">
                    <div className="flex items-center justify-between space-x-2 mb-1">
                        <div className="font-bold text-slate-300">{title}</div>
                        <time className="font-caveat font-medium text-indigo-100">{formatter.format(date)}</time>
                    </div>
                    <div className="text-slate-400">
                        {description}
                    </div>
                    <div className={"mt-5 flex flex-row"}>
                        <EditHistory updateHistory={updateHistory} editHistory={{
                            date: date,
                            description: description,
                            title: title,
                            id: id
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className={"fill-orange-300 hover:fill-orange-400 cursor-pointer mx-2 transition-all duration-500"}
                                 height="16" width="16"
                                 viewBox="0 0 512 512">
                                <path
                                    d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/>
                            </svg>
                        </EditHistory>

                        <DeleteHistory updateHistory={updateHistory} history={{
                            date: date,
                            description: description,
                            title: title,
                            id: id

                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14"
                                 className={"fill-red-500 hover:fill-red-600 cursor-pointer mx-2 transition-all duration-500"}
                                 viewBox="0 0 448 512">
                                <path
                                    d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                            </svg>
                        </DeleteHistory>
                    </div>
                </div>
            </div>
        </>
    )
}

interface HistoryPreviewComponentProps {
    title: string;
    description: string;
    date: Date | undefined;
}

export const HistoryPreviewComponent: React.FC<HistoryPreviewComponentProps> = ({title, date, description}) => {
    const formatter = new Intl.DateTimeFormat(i18n.language, {month: '2-digit', year: 'numeric', day: '2-digit'})

    return (
        <>
            <div
                className="relative min-w-[200px] flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">
                <div
                    className="w-full max-w-[500px] bg-primary p-4 rounded-lg shadow">
                    <div className="flex items-center justify-between space-x-2 mb-1">
                        <div className="font-bold text-slate-300">{title}</div>
                        <time
                            className="font-caveat font-medium text-indigo-100">{!date ? "00.00.0000" : formatter.format(date)}</time>
                    </div>
                    <div className="text-slate-400">
                        {description}
                    </div>
                </div>
            </div>
        </>
    )
}

export default HistoryDashboard;