import React, {useEffect, useState} from "react";
import {useKeenSlider} from "keen-slider/react";
import {IPodcast} from "../types";

const PodcastComponent = () => {
    const [podcastItems, setPodcastItem] = useState<IPodcast[]>([]);
    const [currentSlide, setCurrentSlide] = React.useState(0)
    const [sliderRef, instanceRef] = useKeenSlider({
        mode: "snap",
        renderMode: "performance",
        slides: {
            origin: "center",
            perView: 1,
            spacing: 15,
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel)
        },
    })

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/station/podcast/1/1ef12f8b-fed7-6482-9973-a3392813f81a').then(value => value.json()).then(value => {
            setPodcastItem(value)
            setTimeout(() => instanceRef.current?.update(), 2000)
        })
    }, [instanceRef]);

    return (
        <>
            <section className="block static p-[50px] w-full">
                <h1 className="p-0 m-0 text-center text-[45px] font-bold">Podcasts</h1>
                <div ref={sliderRef} className="keen-slider">
                    {podcastItems.map((value, index: number) => {
                        return (
                            <div className="keen-slider__slide" key={index}>
                                <a rel="noreferrer" target="_blank" href={value.link} style={{textDecoration: "none", color: "#FFFFFF"}}>
                                <div className="h-[400px] w-[290px] bg-[#212121] border-2 border-[#2A2929FF] mx-auto my-[30px] rounded-xl">
                                    <div className="w-[150px] mx-auto z-50 my-[10px]">
                                        <img className={"rounded-xl w-[150px]"} src={value.art} alt=""/>
                                    </div>
                                    <h1 className="text-center text-xl mx-2 mb-1 font-bold">{value.title}</h1>
                                    <p className={"w-[250px] mx-auto ml-[20px] text-center overflow-hidden line-clamp-6"} style={{display: "-webkit-box"}}>{value.description}</p>
                                </div>
                                </a>
                            </div>
                        )
                    })}
                </div>
                {instanceRef.current && (
                    <div className="relative w-full flex py-[10px] justify-center">
                        {instanceRef.current?.slides.map((idx, index) => {
                            return (
                                <button
                                    key={index}
                                    onClick={() => {
                                        instanceRef.current?.moveToIdx(index)
                                    }}
                                    className={"w-[10px] h-[10px] rounded-full p-[5px] cursor-pointer mx-[5px] border-[2px] border-[#626262FF]" + (currentSlide === index ? " bg-[#808080FF]" : " bg-[#626262B2]")}
                                ></button>
                            )
                        })}
                    </div>
                )}
            </section>
        </>
    )
}

export default PodcastComponent;