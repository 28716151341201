import React from "react";
import BasicPopup from "./BasicPopup";
import {ITeamspeakBotSettings} from "../../types";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface Props {
    teamspeakBot: ITeamspeakBotSettings | undefined;
    children: JSX.Element;
}

export const DeleteTeamspeakBot: React.FC<Props> = ({teamspeakBot, children}) => {
    const {t} = useTranslation();
    const [open, setOpen] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const close = () => {
        setOpen(false);
    }

    return(
        <>
            {React.cloneElement(children, {onClick: () => setOpen(true)})}
            {open ?
            <BasicPopup closeFunction={close} title={"Bot wirklich Löschen?"} active={open}>
                <div className="flex flex-col my-2">
                    <label className="text-xl text-center py-5 mb-2">Möchtest du den Bot wirklich löschen?</label>
                    <button className="p-1 rounded-lg bg-red-500 hover:bg-red-600 transition-all duration-300 max-w-72 mx-auto" onClick={() => {
                        fetch(process.env.REACT_APP_API_URL + '/musicbot/' + teamspeakBot?.id, {
                            method: 'DELETE',
                            headers: {
                                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
                            }
                        }).then(async (value) => {
                            close();
                            if (value.status !== 200) {
                                toast.error(t((await value.json()).message));

                                return;
                            }
                            toast.success(t((await value.json()).message));
                            if (location.key !== "default") {
                                navigate(-1);
                            } else {
                                navigate('/dashboard');
                            }
                        })
                    }}>{t("teamspeakbots.deleteYes")}</button>
                </div>
            </BasicPopup>
            : <></>}
        </>
    )
}