import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {IProfile} from "../component/types";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";

const ProfilePage = () => {
    const {username} = useParams();
    const navigate = useNavigate();
    const {stations} = useSelector((state: any) => state.stations);
    const {t} = useTranslation();
    const [profile, setProfile] = useState<null | IProfile>(null);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + `/profile/${username}`).then(async value => {
            if (value.status !== 200) {
                toast.error(t((await value.json()).message))
                navigate('/');
            }
            value.json().then(value1 => setProfile(value1))
        })
    }, []);

    return (
        <>
            <div className={"top"}>
                <div className={"pt-52 w-full mx-auto max-w-[1920px] flex flex-col justify-center items-center"}>
                    <div className="px-5 flex flex-row ustify-center items-center">
                        <img className="w-36 bg-primary p-2 rounded-lg" src={profile?.picture}
                             alt={"User profile picture"}/>
                        <div className="ml-5">
                            <h1 className={"text-2xl font-bold"}>{profile?.username}</h1>
                            <div className={""}>
                                {profile?.role.filter(value1 => value1 !== "team").map(value => value && value[0].toUpperCase() + value.slice(1)).join(", ")}
                            </div>
                        </div>
                    </div>
                    <div className="py-5">
                        <h1 className={"text-center text-xl font-bold my-5"}>{t('profile.userListening')}</h1>
                        <h2 className="text-center text-lg my-5">{t('profile.website')}</h2>
                        <div className={"flex flex-row flex-wrap justify-center"}>
                            {profile?.listening?.website.length === 0 ?
                                <>
                                    <p className="text-center w-full">{t('profile.noListenOnWebsite')}</p>
                                </>
                                : <></>}
                            {profile?.listening?.website.map((value, index) => {
                                const filter = stations.filter((value1: any) => value1.short_name === value.station);
                                let cover;
                                if (filter.length === 0) {
                                    cover = "";
                                } else {
                                    cover = filter[0].current_playing.covers.coverMedium;
                                }
                                return (
                                    <>
                                        <div
                                            className="w-52 h-20 relative rounded-xl bg-primary justify-center items-center flex flex-col m-2"
                                            data-aos="fade-up" data-aos-delay={100 + 50 * index}>
                                            <div
                                                className="absolute top-0 left-0 w-full h-full rounded-xl opacity-40 bg-cover bg-center"
                                                style={{backgroundImage: 'url(' + (cover) + ')'}}/>
                                            <h1 className="text-xl font-bold text-center relative">{value.station}</h1>
                                            <h2 className="text-lg font-medium text-center relative">{value.time} Minuten</h2>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                        <h2 className="text-center text-lg my-5">{t('profile.discord')}</h2>
                        <div className={"flex flex-row flex-wrap justify-center"}>
                            {profile?.listening?.discord.length === 0 ?
                                <>
                                    <p className="text-center w-full">{t('profile.noListenOnDiscord')}</p>
                                </>
                                : <></>}
                            {profile?.listening.discord.map(value => {
                                const filter = stations.filter((value1: any) => value1.short_name === value.station);
                                let cover;
                                if (filter.length === 0) {
                                    cover = "";
                                } else {
                                    cover = filter[0].current_playing.covers.coverMedium;
                                }
                                return (
                                    <>
                                        <div
                                            className="w-52 h-20 relative rounded-xl bg-primary justify-center items-center flex flex-col m-2">
                                            <div
                                                className="absolute top-0 left-0 w-full h-full rounded-xl opacity-40"
                                                style={{background: 'url(' + (cover) + ')'}}/>
                                            <h1 className="text-xl font-bold text-center">{value.station}</h1>
                                            <h2 className="text-lg font-medium text-center">{value.time} Minuten</h2>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfilePage;