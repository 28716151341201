import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import io, {Socket} from 'socket.io-client';
import {DefaultEventsMap} from "socket.io/dist/typed-events";
import {useSelector} from "react-redux";

interface ILiveChat {
    type: "message" | "song_request",
    content: string | any,
    author: string,
}

const LivechatComponent = () => {
    const {stations} = useSelector((state: any) => state.stations);
    const {user} = useSelector((state: any) => state.user);
    const {player} = useSelector((state: any) => state.player);
    const [cookies] = useCookies(['uXgZvxBcmT']);

    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState<ILiveChat[]>([]);
    const [socket, setSocket] = useState<Socket<DefaultEventsMap, DefaultEventsMap>>();

    useEffect(() => {
        if (cookies.uXgZvxBcmT) {
            if (socket) socket.disconnect();
            const socketIO = io(process.env.REACT_APP_API_URL ?? "", {
                extraHeaders: {
                    Authentication: cookies.uXgZvxBcmT
                }
            });
            setSocket(socketIO);
        }
    }, [cookies.uXgZvxBcmT])

    useEffect(() => {
        if (socket) {
            socket.on('connect', () => {
                setSocket(socket);
                socket.emit("request_all_messages")
                socket.on("receive_message", (message: any) => {
                    receivedMessage(message)
                });
                socket.on("song_request", (message: any) => {
                    receivedMessage(message)
                });
                socket.on("send_all_messages", (message: any) => {
                    setMessages(message)
                });
            })
        }
    }, [socket])

    const receivedMessage = (message: any) => {
        setMessages(prev => [...prev, message])
    }

    const [isLivechatActive, setIsLivechatActive] = useState(false);
    if(!stations[0]) return (<></>);
    if (!stations[0].live.is_live) return (<></>);
    if (!user) return (<></>);

    return (
        <>
            <div>
                <div onClick={() => setIsLivechatActive(true)}
                     className={"fixed w-[60px] rounded-full text-white cursor-pointer h-[60px] right-[50px] bottom-[50px] z-10 bg-secondary" + (player.isActive ? " -translate-y-10 duration-500 transition-all ease-linear" : "")}>
                    <div id="chat-overlay"></div>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         className="w-[35px] block mx-auto my-[13px] fill-white"
                         viewBox="0 0 512 512">
                        <path
                            d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM224 160c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v48h48c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H288v48c0 8.8-7.2 16-16 16H240c-8.8 0-16-7.2-16-16V272H176c-8.8 0-16-7.2-16-16V224c0-8.8 7.2-16 16-16h48V160z"/>
                    </svg>
                </div>

                <div
                    className={"z-40 fixed bottom-[50px] right-[30px] max-w-[85vw] max-h-[100vh] w-[350px] rounded-xl bg-secondary shadow-primary shadow-xl" + (isLivechatActive ? " block" : " hidden") + (player.isActive ? " -translate-y-10 duration-500 transition-all ease-linear" : "")}>
                    <div className="grid grid-cols-2 w-full h-[40px] my-[18px] border-b-2 border-primary">
                        <h1 className="text-xl font-bold pl-[18px]">Livechat</h1>
                        <span className="fill-white w-[17px] h-[17px] absolute right-[18px]"
                              onClick={() => setIsLivechatActive(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path
                                d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg></span>
                    </div>
                    <div className="chat-box-body">
                        <div className="chat-box-overlay">
                        </div>
                        <div className="chat-logs">
                            {messages.map((value, index) => {
                                if (value.content === "") return (<></>)
                                if (value.type === "song_request" && value.content === "") return (<></>)
                                if (value.type === "song_request") {
                                    return (
                                        <>
                                            <div key={index} id={'cm-msg-' + index} className="my-2">
                                                <div className="w-full rounded-lg mx-auto bg-primary h-16 cursor-pointer relative flex truncate">
                                                    <img src={value.content.cover} className="h-16 rounded-xl p-1" alt="Cover"/>
                                                    <div className="flex flex-col justify-center h-full">
                                                        <div className="text-white font-bold text-lg">{value.content.title}</div>
                                                        <div className="text-white text-sm">{value.content.artist.name}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <div key={index} id={'cm-msg-' + index}
                                                 className={"chat-msg" + ((user.username ?? "") === value.author ? " self" : "")}>
                                                <div className="cm-msg-text">
                                                    <div className="color">
                                                        {value.content}
                                                    </div>
                                                </div>
                                                <div className={"username"}>
                                                    {value.author}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <div className="w-full rounded-b-lg pt-[18px]">
                        <form onSubmit={(event) => {
                            event.preventDefault();
                            socket?.emit("send_message", message)
                            setMessage("")
                        }}>
                            <input type="text"
                                   className="block bg-[#3f3f3f] rounded-bl-lg w-[85%] h-[40px] placeholder:text-[#f6f6f6] border-none resize-none outline-0 text-white"
                                   value={message}
                                   onChange={(event) => setMessage(event.target.value)}
                                   placeholder="Send a message..."/>
                            <button type="submit" className="absolute bottom-[5px] w-[30px] h-[30px] right-[10px]"
                                    id="chat-submit">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 512 512"
                                     fill={"white"}>
                                    <path
                                        d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/>
                                </svg>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LivechatComponent;