import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import toast from 'react-hot-toast';
import {useNavigate} from "react-router-dom";

interface Props {
}

const NewPasswordComponent: React.FC<Props> = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [newPassword, setNewPassword] = useState({
        password: "",
        repeatPassword: "",
    });

    const changeHandler = (e: { target: { name: any; value: any; }; }) => {
        setNewPassword({...newPassword, [e.target.name]: e.target.value});
    }

    const newPasswordRequest = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        const urlencoded = new URLSearchParams();
        urlencoded.append("password", newPassword.password);
        urlencoded.append("repeatPassword", newPassword.repeatPassword);
        fetch(process.env.REACT_APP_API_URL + '/user/resetPassword/' + window.location.search.split("=")[1], {
            method: 'PUT',
            body: urlencoded,
            headers: myHeaders
        }).then(value => {
            value.json().then(value1 => {
                if (value.status !== 201) {
                    toast.error(value1.message);
                } else {
                    toast.success(t(value1.message));
                    setTimeout(() => {
                        navigate("/signin")
                    }, 2000)
                }
            })
        })
    }


    return (
        <>
            <div className="top">
                <h1 className="lg:pt-[150px] pt-[120px] text-4xl text-center font-bold pb-10">{t('newPassword.title')}</h1>
                <div className={"xl:w-1/3 w-3/4 mx-auto pb-5"}>
                    <form onSubmit={newPasswordRequest}>
                        <div className={"flex flex-col lg:w-1/2 w-full mx-auto pb-5"}>
                            <label className="text-lg font-medium">{t('newPassword.password')}</label>
                            <input name="password" className="py-1 px-2 bg-secondary rounded-lg border border-gray-700"
                                   placeholder={"Passwort"}
                                   type="password"
                                   onChange={changeHandler} required={true}></input>
                        </div>
                        <div className={"flex flex-col lg:w-1/2 w-full mx-auto pb-5"}>
                            <label className="text-lg font-medium">{t('newPassword.resetPassword')}</label>
                            <input name="repeatPassword"
                                   className="py-1 px-2 bg-secondary rounded-lg border border-gray-700"
                                   placeholder={"Passwort"}
                                   type="password"
                                   onChange={changeHandler} required={true}></input>
                        </div>
                        <div className={"lg:w-1/2 w-full mx-auto text-center"}>
                            <button type="submit"
                                    className="py-2 px-3 border border-gray-800 rounded-lg mx-auto mt-5 bg-secondary">{t('newPassword.resetPassword')}</button>
                        </div>
                    </form>
                    <div className="text-center pt-5">
                        <p onClick={() => navigate('/signin')}
                           className={"cursor-pointer w-auto"}>{t('newPassword.backToLogin')}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewPasswordComponent;