import React, {useEffect, useRef, useState} from 'react';
import './Header.css'
import logo from '../assets/Logo.svg'
import {NavLink, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import dashboard from "./img/dashboard.svg";
import partner from "./img/partner.svg";
import settings from "./img/settings.svg";
import logout from "./img/logout.svg";
import {useSelector} from "react-redux";

const StateHooksComponent = () => {
    const {user} = useSelector((state: any) => state.user);

    const [dropdownBoolean, setDropdownBoolean] = useState<boolean>();
    const {t} = useTranslation();

    const [open, setOpen] = useState<boolean>(false);
    let menuRef = useRef<HTMLDivElement>();
    useEffect(() => {
        let handler = (e: MouseEvent) => {
            try {
                if (!menuRef.current?.contains(e.target as Node)) {
                    setOpen(false);
                }
            } catch (error) {
            }
        };
        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
        }
    });

    function dropdown() {
        setDropdownBoolean(!dropdownBoolean)
    }


    return (
        <>
            <header className={dropdownBoolean ? "changeBackground" : ""}
                    style={dropdownBoolean ? {height: "100vh"} : {}}>
                <img src={logo} alt=""/>
                <div className="desktop">
                    <ul>
                        <li><NavLink to="/">{t("layout.homepage")}</NavLink></li>
                        <div className="line"></div>
                        <li><NavLink to="/bots">{t("layout.bots")}</NavLink></li>
                        <div className="line"></div>
                        <li><NavLink to="/team">{t("layout.team")}</NavLink></li>
                        <div className="line"></div>
                        <li><NavLink to="/partner">{t("layout.partner")}</NavLink></li>
                        <div className="line"></div>
                        <li><NavLink to="/history">{t("layout.history")}</NavLink></li>
                    </ul>
                    <ul>
                        {!user ?
                            (
                                <>
                                    <li><NavLink to="/signin">Anmelden</NavLink></li>
                                    <div className="line"></div>
                                    <li><NavLink to="/signup">Register</NavLink></li>
                                </>
                            )
                            : <>
                                <UserDropDown menuRef={menuRef} open={open}
                                              setOpen={setOpen} t={t}/>
                            </>}
                    </ul>
                </div>
                <div className={"mobile"}>
                    <div className="mobileLogin">
                        {!user ?
                            <></>
                            :
                            <div style={{marginTop: "35px"}}>
                                <UserDropDown menuRef={menuRef} open={open}
                                              setOpen={setOpen} t={t}/>
                            </div>
                        }
                    </div>
                    <div className="icon" onClick={() => dropdown()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill={"#FFFFFF"} viewBox="0 0 448 512">
                            <path
                                d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"/>
                        </svg>
                    </div>
                    <div className={dropdownBoolean ? "items active" : "items"}>
                        <ul>
                            <li><NavLink onClick={() => dropdown()} to="/">Startseite</NavLink></li>
                            <div className="line"></div>
                            <li><NavLink onClick={() => dropdown()} to="/bots">Bots</NavLink></li>
                            <div className="line"></div>
                            <li><NavLink onClick={() => dropdown()} to="/team">Team</NavLink></li>
                            <div className="line"></div>
                            <li><NavLink onClick={() => dropdown()} to="/partner">Partner</NavLink></li>
                            <div className="line"></div>
                            <li><NavLink onClick={() => dropdown()} to="/history">Historie</NavLink></li>
                        </ul>
                        {!user ?
                            (
                                <>
                                    <ul>
                                        <li><NavLink to="/signin">Anmelden</NavLink></li>
                                        <div className="line"></div>
                                        <li><NavLink to="/signup">Register</NavLink></li>
                                    </ul>
                                </>) : <></>}
                    </div>
                </div>
            </header>
        </>
    )
}

interface UserDropDownProps {
    menuRef: any;
    setOpen: (open: boolean) => void;
    open: boolean;
    t: any;
}

const UserDropDown: React.FC<UserDropDownProps> = ({menuRef, setOpen, open, t}) => {
    const {user} = useSelector((state: any) => state.user);

    if (!user) return (<></>);

    return (
        <>
            <div className='menu-container' ref={menuRef}>
                <div className='cursor-pointer menu-trigger' onClick={() => {
                    setOpen(!open)
                }}>
                    <p style={{margin: "0"}} className="font-bold">{user.username}</p>
                </div>

                <div className={`dropdown-menu box-content ${open ? 'active' : 'inactive'}`}>
                    <p className={"roleName"}>Role<br/><span>{user.role.filter((value: string) => value.toString() !== "team").map((value: string | any[]) => value && value[0].toUpperCase() + value.slice(1)).join(", ")}</span>
                    </p>
                    {user.role.includes("admin") ?
                        <>
                            <ul className={"adminDropdown"}>
                                <DropdownItem navlink={'/admin'} img={partner} text={t('header.admin')}/>
                            </ul>
                        </>
                        : <></>}
                    {user.role.includes("partner") ?
                        <>
                            <ul className={"partnerDropdown"}>
                                <DropdownItem navlink={'/dashboard/partner'} img={partner} text={t('header.partner')}/>
                            </ul>
                        </>
                        : <></>}
                    <ul>
                        <DropdownItem navlink={'/dashboard'} img={dashboard} text={t('header.dashboard')}/>
                        <DropdownItem navlink={'/dashboard/settings'} img={settings} text={t('header.settings')}/>
                        <DropdownItem type={"logout"} img={logout} text={t('header.logout')}/>
                    </ul>
                </div>
            </div>
        </>
    )
}

function DropdownItem(props: any) {
    const navigate = useNavigate();

    return (
        <li className='dropdownItem' onClick={() => navigate(props.navlink)}>
            <img src={props.img} alt={props.text}/>
            {props.navlink !== undefined ?
                <NavLink to={props.navlink} end={true}> {props.text} </NavLink>
                :
                props.type === 'logout' ?
                    <div onClick={() => {
                        navigate('/')
                        Cookies.remove('uXgZvxBcmT')
                    }
                    }> {props.text} </div>
                    : <></>
            }
        </li>
    );
}

export default StateHooksComponent;