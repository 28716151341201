import React from 'react';
import './i18nextConf';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import "@fontsource/roboto";
import App from "./component/App";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {Provider} from "react-redux";
import store from "./features/store/store";

AOS.init({
    once: true
});

const root = ReactDOM.createRoot(
    document.getElementsByTagName("body")[0] as HTMLElement
);

root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);

reportWebVitals();
