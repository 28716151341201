import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import BasicPopup from "./BasicPopup";
import {HistoryPreviewComponent} from "../../content/dashboard/admin/HistoryDashboard";

interface Props {
    children: JSX.Element;
    updateHistory: () => void;
    editHistory: {
        id: number;
        title: string;
        description: string;
        date: Date;
    };
}

interface CreateHistory {
    title: string;
    description: string;
    date: Date | undefined;
}

const EditHistory: React.FC<Props> = ({children, updateHistory, editHistory}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [history, setHistory] = React.useState<CreateHistory>({
        title: "",
        description: "",
        date: undefined,
    });
    const {t} = useTranslation();
    const close = () => {
        setOpen(false);
    }

    useEffect(() => {
        setHistory({
            title: editHistory.title,
            description: editHistory.description,
            date: new Date(editHistory.date),
        });
    }, [editHistory.date, editHistory.description, editHistory.title]);

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        if(e.target.name === "date") {
            setHistory({...history, [e.target.name]: new Date(e.target.value)});
            return;
        }
        setHistory({...history, [e.target.name]: e.target.value});
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!history) return;
        fetch(process.env.REACT_APP_API_URL + '/history/' + editHistory.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            },
            body: JSON.stringify(history),
        }).then((value) => {
            if (value.status !== 200) {
                toast.error(t('history.editError'));
            } else {
                toast.success(t('history.editSuccess'));
                updateHistory();
            }
            close();
        })
    }

    return (
        <>
            {React.cloneElement(children, {onClick: () => setOpen(true)})}
            {open ?
                <BasicPopup closeFunction={close} title={"History Point Erstellen"} active={open}>
                    <form onSubmit={onSubmit}>
                        <div className={"flex md:flex-row flex-col w-full relative"}>
                            <div className={"flex flex-col md:w-1/2 w-full m-2"}>
                                <label className="text-lg py-1">{t("history.title")}</label>
                                <input type="text" defaultValue={history.title} className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler} required={true}
                                       name={"title"} placeholder={t("history.title").toString()}/>
                            </div>
                            <div className={"flex flex-col md:w-1/2 w-full m-2"}>
                                <label className="text-lg py-1">{t("history.date")}</label>
                                <input type="date" defaultValue={history.date?.toLocaleDateString()} className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler} required={true}
                                       name="date" placeholder={t("history.description").toString()}/>
                            </div>
                        </div>
                        <div className={"flex md:flex-row flex-col relative"}>
                            <div className={"flex flex-col w-full m-2"}>
                                <label className="text-lg py-1">{t("history.description")}</label>
                                <textarea defaultValue={history.description} name="description" className="p-1 w-full rounded-lg bg-secondary" onChange={changeHandler}
                                       placeholder={t("history.description").toString()}/>
                            </div>
                        </div>
                        <button className="p-1 rounded-lg bg-secondary w-full ml-2 mt-5"
                                type={"submit"}>{t("history.save")}</button>
                    </form>
                    <h2 className="text-center text-xl font-bold my-5">Preview</h2>
                    <HistoryPreviewComponent date={history.date} title={history.title} description={history.description}/>
                </BasicPopup>
                : <></>}
        </>
    )
}

export default EditHistory;