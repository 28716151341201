import React from "react";

interface Props {
    flame: boolean
}

const Candle: React.FC<Props> = ({flame}) => {
    return (
        <>
            <div className="flex relative flex-col justify-center items-center z-[2] mt-[10px]">
                {flame ?
                    <div className="w-[18px] h-[18px] bg-[#FFD678] absolute z-[6] rounded-candle left-1/2 top-[-13px] ml-[-9px] rotate-[-45deg] animate-flameFlicker">
                        <div className="w-[10px] h-[10px] rounded-candle bg-[#D1835C] absolute z-[8] bottom-[2px] ml-[2px] opacity-40"></div>
                    </div>
                    : <></>}
                <div className="w-[4px] h-[12px] bg-[#111] z-[5] left-1/2 top-[-8px] rounded-t"></div>
                <div className="w-[30px] h-[60px] bg-[#A65A51] rounded-[8px] relative m-auto z-[3]">
                    <div className="w-[30px] h-[17px] bg-[#b97871] rounded-[100%] absolute top-[-4px] overflow-hidden">
                        <div className="w-[30px] h-[16px] bg-[#c28882] rounded-[100%] absolute top-[3px]"></div>
                    </div>
                    <div className="w-[30px] h-[17px] bg-[#A65A51] rounded-[100%] absolute bottom-[-2px]"></div>
                    <div className="w-[8px] h-[10px] bg-[#c7928c] absolute top-[10px] left-[8px] rounded-candle">
                        <div className="falling-wax"></div>
                    </div>
                    <div className="wax-droppings">
                        <div className="more-droppings"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Candle;