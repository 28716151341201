import React, {ChangeEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import toast from 'react-hot-toast';
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

interface Props {
}

const LoginComponent: React.FC<Props> = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [login, setLogin] = useState({
        email: "",
        password: "",
        thirtyDaysLogin: false,
    });

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.type === "checkbox") {
            setLogin({...login, [e.target.name]: e.target.checked});
        } else {
            setLogin({...login, [e.target.name]: e.target.value});
        }
    }

    const loginRequest = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        const urlencoded = new URLSearchParams();
        urlencoded.append("email", login.email);
        urlencoded.append("password", login.password);
        urlencoded.append("thirtyDaysLogin", String(login.thirtyDaysLogin));
        fetch(process.env.REACT_APP_API_URL + '/user/login', {
            method: 'POST',
            body: urlencoded,
            headers: myHeaders
        }).then(value => {
            value.json().then(value1 => {
                if (value.status !== 200) {
                    toast.error(t(value1.message));
                } else {
                    Cookies.set('uXgZvxBcmT', value1.access_token)
                    toast.success(t(value1.message));
                    navigate("/")
                }
            })
        })
    }

    const handleMessage = (event: MessageEvent) => {
        event.data.split("&").forEach((value: string) => {
            console.log(value)
            const value1 = value.split("=")
            if (value1[0] === "access_token") {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                const urlencoded = new URLSearchParams();
                urlencoded.append("discordToken", value1[1]);
                fetch(process.env.REACT_APP_API_URL + '/user/discordLogin', {
                    method: 'POST',
                    body: urlencoded,
                    headers: myHeaders
                }).then(value => {
                    value.json().then(value1 => {
                        if (value.status !== 200) {
                            toast.error(t(value1.message));
                        } else {
                            Cookies.set('uXgZvxBcmT', value1.access_token)
                            toast.success(t(value1.message));
                            setTimeout(() => {
                                navigate("/")
                            }, 2000)
                        }
                    })
                })
            }
        })
    }

    function onClick() {
        window.open(`https://discord.com/api/oauth2/authorize?response_type=token&client_id=846282107462352927&scope=identify%20email%20guilds&redirect_uri=` + process.env.REACT_APP_LOGIN_CALLBACK, 'discordLogin', 'height=880,width=500')
        window.addEventListener('message', handleMessage, false)
    }


    return (
        <>
            <div className="top">
                <h1 className="lg:pt-[150px] pt-[120px] text-4xl text-center font-bold pb-10">{t('login.title')}</h1>
                <div className={"xl:w-1/3 w-3/4 mx-auto pb-5"}>
                    <form onSubmit={(event) => loginRequest(event)}>
                        <div className={"flex flex-col lg:w-1/2 w-full mx-auto pb-5"}>
                            <label className="text-lg font-medium">{t('login.email')}</label>
                            <input name="email" type={"email"} className="py-1 bg-secondary px-2 outline-0 resize-none rounded-lg border border-gray-700"
                                   placeholder={"info@breathfm.de"}
                                   onChange={event => changeHandler(event)} required={true}></input>
                        </div>
                        <div className={"flex flex-col lg:w-1/2 w-full mx-auto pb-5"}>
                            <label className="text-lg font-medium">{t('login.password')}</label>
                            <input name="password" className="py-1 px-2 bg-secondary rounded-lg border border-gray-700" placeholder={"Passwort"}
                                   type="password"
                                   onChange={event => changeHandler(event)} required={true}></input>
                        </div>
                        <div className="flex items-center lg:w-1/2 w-full mx-auto">
                            <input id="link-checkbox" type="checkbox" name={"thirtyDaysLogin"} value="false" onChange={changeHandler} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label htmlFor="link-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">30 Tage Angemeldet bleiben.</label>
                        </div>
                        <div className={"lg:w-1/2 w-full mx-auto text-center"}>
                            <button type="submit"
                                    className="py-2 px-3 border border-gray-700 rounded-lg mx-auto mt-5 bg-secondary">{t('login.loginButton')}</button>
                        </div>
                    </form>
                    <div className={"flex flex-row justify-center items-center pt-5"}>
                    <button className="bg-[#7289da] rounded-lg w-12 h-10" onClick={onClick}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             fill="white"
                             className={"w-8 h-8 mx-auto"}
                             viewBox="0 0 640 512">
                            <path
                                d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"/>
                        </svg></button>
                    </div>
                    <div className="text-center pt-5">
                        <p onClick={() => navigate('/forget')} className={"cursor-pointer w-auto"}>{t('login.forgetPassword')}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginComponent;