import React from "react";
import {IPartner} from "../types";

interface Props {
    partner: IPartner;
    index?: number;
    children?: JSX.Element;
}

const PartnerItemComponent: React.FC<Props> = ({partner, index = 0, children}) => {
    return (
        <>
            <div className={"w-full my-5 lg:max-h-[250px] rounded-lg relative"}
                 data-aos="fade-up" data-aos-delay={index * 50}>
                <div className={"absolute w-full h-full rounded-lg z-0 blur-[2px]"}
                     style={{
                         backgroundImage: "linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.7)), url(" + (partner.thumbnail) + ")",
                         backgroundPosition: "center",
                         backgroundSize: "cover",
                     }}/>
                <div className="flex lg:flex-row relative h-full flex-col">
                    <div className="lg:w-1/4 w-full flex justify-center items-center">
                        <img alt={partner.name + "-logo"}
                             className={"max-h-[150px] lg:py-0 py-2 max-w-[250px] rounded-lg"}
                             src={partner.logo}/>
                    </div>
                    <div className={"lg:w-2/4 w-full px-2 lg:px-0"}>
                        <h1 className={"text-2xl font-bold my-5"}>{partner.name}</h1>
                        <div className={"flex"}>
                            {partner.short_description.split(", ").map(partner1 => {
                                return (
                                    <div className="rounded-full border-2 py-0.5 px-2">{partner1}</div>
                                )
                            })}
                        </div>
                        <p className={"my-5 lg:w-3/4 text-ellipsis"}>
                            {partner.description}
                        </p>
                    </div>
                    <div className="lg:w-1/4 flex flex-row justify-end items-center pr-5">
                        {partner.socialMedia.map((socialMedia) => {
                            switch (socialMedia.type) {
                                case "twitter":
                                    return (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                                 onClick={() => window.open(socialMedia.content)}
                                                 stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="w-8 hover:stroke-blue-600 stroke-[#1d9bf0] transition-all cursor-pointer mx-2">
                                                <path
                                                    d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                                            </svg>
                                        </>
                                    )
                                default:
                                    return (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 onClick={() => window.open(socialMedia.content)}
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                 strokeWidth="2" strokeLinecap="round"
                                                 strokeLinejoin="round"
                                                 className="w-8 hover:stroke-gray-300 transition-all cursor-pointer mx-2">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="2" y1="12" x2="22" y2="12"></line>
                                                <path
                                                    d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                                            </svg>
                                        </>
                                    )
                            }
                        })}
                    </div>
                    {children}
                </div>
            </div>
        </>
    )
}

export default PartnerItemComponent;