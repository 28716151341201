import React, {useEffect} from "react";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface Props {
}

const AccountDeletingComponent: React.FC<Props> = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/user/verify/${window.location.search.split("=")[1]}`, {
            method: "DELETE",
        }).then(async value => {
            if (value.status === 200) {
                toast.success(t((await value.json()).message))
            } else {
                toast.error(t((await value.json()).message));
            }
            navigate("/");
        })
    }, [])

    return (<></>)
}

export default AccountDeletingComponent;