import React from "react";

interface Props {
}

const TermsOfService: React.FC<Props> = () => {
    return (
        <>
            <div className="impressum-container">
                <div className="item">
                    <div className="box">
                        <h1>Terms of Service</h1>
                        <h2>Usage Agreement</h2>
                        <p>By inviting the bot and using its features (commands, welcome system) are you agreeing to the
                            below mentioned Terms and Privacy Policy (Policy) of the bot.</p>
                        <br/>
                        <p>You acknowledge that you have the priviledge to use the bot freely on any Discord Server
                            (Server) you share with it, that you can invite it to any Server that you have "Manage
                            Server" rights for and that this priviledge might get revoked for you, if you're subject of
                            breaking the terms and/or policy of this bot, or the Terms of Service, Privacy Policy and/or
                            Community Guidelines of Discord Inc.</p>
                        <br/>
                        <p>Through Inviting the bot may it collect specific data as described in its Policy.
                            The intended usage of this data is for core functionalities of the bot such as command
                            handling, guild-specific settings and the Welcoming System.</p>
                        <br/>
                        <h2>Intended Age</h2>
                        <p>The bot may not be used by individuals under the minimal age described in Discord's Terms of
                            Service.
                            Features, such as commands which are marked as "NSFW" (Not safe for work) may not be used by
                            individuals under the age of maturity in their country. Doing so will be seen as a violation
                            of these terms and will result in a removal of the bot from any servers you own.</p>
                        <br/>
                        <h2>Affiliation</h2>
                        <p>The Bot is not affiliated with, supported or made by Discord Inc.
                            Any direct connection to Discord or any of its Trademark objects is purely coincidental. We
                            do not claim to have the copyright ownership of any of Discord's assets, trademarks or other
                            intellectual property.</p>
                        <br/>
                        <h2>Liability</h2>
                        <p>The owner of the bot may not be made liable for individuals breaking these Terms at any
                            given time.
                            He has faith in the end users being truthfull about their information and not missusing this
                            bot or The Services of Discord Inc in a malicious way.</p>
                        <br/>
                        <p>We reserve the right to update these terms at our own discretion, giving you a 1-Week (7
                            days) period to opt out of these terms if you're not agreeing with the new changes.
                            You may opt out by Removing the bot from any Server you have the rights for.</p>
                        <br/>
                        <h2>Contact</h2>
                        <p>People may get in contact through e-mail at system@breathfm.de, or through the official
                            Support Discord of the Bot.</p>
                        <p>Other ways of support may be provided but aren't guaranteed.</p>
                        <br/>
                        <h2>Updating Data</h2>
                        <p>The data may be updated when using specific commands.</p>
                        <p>Updating data will require the input of an end user, and data that can be seen as
                            sensitive,
                            such as content of a message, may need to be stored when using certain commands.</p>

                        <p>No other actions may update the stored information at any given time.</p>
                        <br/>
                        <h2>Temporarely stored Information</h2>
                        <p>The Bot may keep the stored information in an internal cacheing mechanic for a certain
                            amount of time.</p>
                        <p>After this time period, the cached information will be dropped and only be re-added when
                            required.</p>
                        <br/>
                        <p>Data may be dropped from cache pre-maturely through actions such as removing the bot from
                            the Server.</p>
                        <br/>
                        <h2>Automatic removal</h2>
                        <p>Stored Data can be removed automatically through means of removing the bot from a Server.
                            This can be achieved by either kicking or banning the bot from the server. Re-inviting the
                            bot will add the same default values, as mentioned above, back to the bot's database.</p>
                        <br/>
                        <h2>Manual removal</h2>
                        <p>Manual removal of the data can be requested through email at support@breathfm.de.
                            For security reasons will we ask you to provide us with proof of ownership of the server,
                            that you wish the data to be removed of. Only a server owner may request manual removal of
                            data and requesting it will result in the bot being removed from the server, if still
                            present on it.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TermsOfService;