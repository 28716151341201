import React, {useEffect, useState} from "react";
import Cookies from "js-cookie";
import {User} from "../../../types";
import EditUserModal from "../../../utils/Popups/UserEditModal";
import DeleteUser from "../../../utils/Popups/DeleteUser";

const UserAdminDashboard = () => {
    const [users, setUsers] = useState<User[]>([]);
    const updateUsers = () => {
        fetch(`${process.env.REACT_APP_API_URL}/user/list`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            },
        }).then(value => {
            value.json().then((value1: User[]) => setUsers(value1.sort((a, b) => a.id - b.id)));
        });
    }

    useEffect(() => {
        updateUsers()
    }, []);

    return (
        <>
            <div className="min-h-[85vh]">
                <h1 className="text-3xl font-bold pt-[150px] text-center">Benutzer Verwalten</h1>
                <div className={"flex flex-row flex-wrap pt-10 items-center w-full justify-center"}>
                    {users.map(value => {
                        return (
                            <>
                                <div className={"w-96 bg-secondary rounded-lg px-5 py-2 m-2"}>
                                    <div className={"flex flex-row"}>
                                        <div className={"w-full"}>
                                            <h1>{value.username}</h1>
                                            <h1>{value.role.join(", ")}</h1>
                                        </div>
                                        <div className="flex flex-row items-center">
                                            <EditUserModal updateUser={updateUsers} editUser={value}>
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className={"fill-orange-300 hover:fill-orange-400 cursor-pointer mx-2 transition-all duration-500"}
                                                     height="16" width="16"
                                                     viewBox="0 0 512 512">
                                                    <path
                                                        d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/>
                                                </svg>
                                            </EditUserModal>
                                            <DeleteUser updateUser={updateUsers} user={value}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14"
                                                     className={"fill-red-500 hover:fill-red-600 cursor-pointer mx-2 transition-all duration-500"}
                                                     viewBox="0 0 448 512">
                                                    <path
                                                        d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                                                </svg>
                                            </DeleteUser>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default UserAdminDashboard;