import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Cookies from "js-cookie";
import {ITeamspeakBotSettings} from "../../types";
import {DeleteTeamspeakBot} from "../../utils/Popups/DeleteTeamspeakBot";
import EditTeamspeakBotComponent from "../../utils/Popups/EditTeamspeakBot";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";

const TeamspeakBotSettings = () => {
    const {id} = useParams();
    const {t} = useTranslation();
    const [teamspeakBot, setTeamspeakBot] = useState<ITeamspeakBotSettings>()

    useEffect(() => {
        updateTeamspeakBot();
    }, []);

    const updateTeamspeakBot = () => {
        fetch(process.env.REACT_APP_API_URL + '/musicbot/' + id + '/info', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            }
        }).then(r => r.json()).then(value => {
            setTeamspeakBot(value)
        })
    }

    const connectTeamspeakBot = async () => {
        await fetch(process.env.REACT_APP_API_URL + '/musicbot/' + id + '/connect', {
            method: 'Post',
            headers: {
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            }
        }).then(async value => {
            if (value.status === 200) {
                toast.success(t((await value.json()).message));
            } else {
                toast.error(t((await value.json()).message));
            }
        })
        updateTeamspeakBot();
    }

    const disconnectTeamspeakBot = async () => {
        await fetch(process.env.REACT_APP_API_URL + '/musicbot/' + id + '/disconnect', {
            method: 'Post',
            headers: {
                'Authorization': 'Bearer ' + Cookies.get('uXgZvxBcmT'),
            }
        }).then(async value => {
            if (value.status === 200) {
                toast.success(t((await value.json()).message));
            } else {
                toast.error(t((await value.json()).message));
            }
        })
        updateTeamspeakBot();
    }


    return (
        <>
            <div className={"top"}>
                <div className="max-w-[1000px] px-5 mx-auto pt-[150px]">
                    <div className={"flex flex-col justify-center items-center"}>
                        <h1 className="text-3xl font-bold text-center">Teamspeak Bot Settings</h1>
                        <h2 className="text-xl font-medium text-center">Hier kannst du die Einstellungen
                            für {teamspeakBot?.name}</h2>
                    </div>

                    <div className={"flex md:flex-row flex-col justify-center pt-10"}>
                        <div className="md:w-3/4 w-full min-h-[100px] m-2 bg-primary rounded-lg relative cursor-pointer"
                             onClick={() => window.open(teamspeakBot?.server?.Server.HostbannerUrl)}>
                            <div className={"absolute w-full h-full bg-cover bg-center rounded-lg opacity-50"}
                                 style={{backgroundImage: `url(${!teamspeakBot?.server?.Server ? '' : teamspeakBot?.server?.Server.HostbannerGfxUrl})`}}/>
                            <div className="relative w-full h-full flex justify-center items-center">
                                {teamspeakBot?.server === undefined ?
                                    <><h1 className={"text-2xl"}>Der Bot ist zurzeit Offline</h1></>
                                    :
                                    <><h1 className={"text-2xl"}>{!teamspeakBot?.server?.Server ? '' : teamspeakBot?.server?.Server.Name }</h1></>
                                }
                            </div>
                        </div>
                        <div
                            className="md:w-1/4 m-2 w-full py-10 bg-primary rounded-lg flex flex-row items-center justify-center">
                            {teamspeakBot?.botId === null ?
                                <>
                                    <div className="rounded-[100%] w-5 h-5 animate-pulse duration-200 bg-red-400"/>
                                    <h1 className="text-xl pl-2">Offline</h1>
                                </>
                                :
                                <>
                                    <div className="rounded-[100%] w-5 h-5 animate-pulse duration-200 bg-green-400"/>
                                    <h1 className="text-xl pl-2">Online</h1>
                                </>
                            }
                        </div>
                    </div>
                    <div className={"flex md:flex-row flex-col justify-center md:pt-10 pt-5"}>
                        <div
                            className="md:w-1/2 w-full min-h-[100px] m-2 bg-primary rounded-lg relative">
                            {teamspeakBot?.botId === null ?
                                <>
                                    <div className="flex h-full w-full justify-center items-center">
                                        <button className="mx-auto text-xl" onClick={connectTeamspeakBot}>Verbinden
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="flex h-full w-full justify-center items-center">
                                        <button className="mx-auto text-xl" onClick={disconnectTeamspeakBot}>Verbindung
                                            Trennen
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                        <div
                            className="md:w-1/2 w-full min-h-[100px] max-h-[500px] m-2 bg-primary rounded-lg">
                            <div className={"flex flex-row justify-center h-full items-center"}>
                                <div className={"w-1/2 flex items-center justify-center h-full"}>
                                    <EditTeamspeakBotComponent teamspeakBot={teamspeakBot !== undefined ? teamspeakBot : null} updateTeamspeakBot={updateTeamspeakBot}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className={"w-6 h-6 fill-amber-400 hover:fill-amber-600 transition-all cursor-pointer"}
                                             viewBox="0 0 512 512">
                                            <path
                                                d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/>
                                        </svg>
                                    </EditTeamspeakBotComponent>
                                </div>
                                <div className={"w-1/2 flex items-center justify-center h-full"}>
                                    <DeleteTeamspeakBot teamspeakBot={teamspeakBot}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className={"w-6 h-6 fill-red-600 hover:fill-red-700 transition-all cursor-pointer"}
                                             viewBox="0 0 448 512">
                                            <path
                                                d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"/>
                                        </svg>
                                    </DeleteTeamspeakBot>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeamspeakBotSettings;